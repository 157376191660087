import React, {Fragment, useEffect, useState} from "react";
import ListingItem from "./ListingItem";
import { useConnection } from '@solana/wallet-adapter-react';
import {fetchSingleListing, ListingAccount} from "@enginefish/solist-sdk/build"
import { backend_api_url, getUserSessionData } from "./constants";
import { PublicKey } from '@solana/web3.js';
import { Link } from "react-router-dom";
import WalletLink from "./WalletLink";

const MyAccount = (props: any) => {

    props.setClassInfo('userPg accountPg');

    const { connection } = useConnection();

    const [currentUserName, setCurrentUserName] = useState<string>("");
	const [avatar, setAvatar] = useState<string>("/avatar-bak.png");
	const [about, setAbout] = useState<string>("");
    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)

    const fetchAllMyListings = async (): Promise<any> => {
        
        const userData = getUserSessionData();

        setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/postings', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    fetchAllListings(data.postings)
                      
                    if(data.userInfo.avatar) {
                        setAvatar(data.userInfo.avatar);
                    }
                    if(data.userInfo.about) {
                        setAbout(data.userInfo.about);
                    }
                }
            });
	};

	const fetchAllListings = async (txn_ids: string[]): Promise<any> => {


        const promises = txn_ids.map(async txn_id => {
            const pubkey =  new PublicKey(txn_id);
            //console.log(txn_id);
           
            const listingAccount = await fetchSingleListing(connection, pubkey);
            return listingAccount;
        });

        const allListings = await Promise.all(promises)
        //console.log(allListings)
        setListingJsx( allListings.map( (listingAccount) => <ListingItem key={listingAccount.id} hideContent={true} hideThumb={true} listingAccount={listingAccount}/>) );

	};

	useEffect(() => {
		
        fetchAllMyListings();
        //fetchAllListings()
	
	  }, []);

    return (
        <Fragment>

        <table className="user" style={ {"border" : "0"} } width="100%">
                
            <tr style={ {"verticalAlign" : "top"} }>
            <td width="140" className="avatar"> <img  alt="" src={avatar}/></td>
            <td>
                <p><span><b>User</b></span>:&nbsp; {currentUserName}</p>
                <p className='user-about'><b>About</b>:&nbsp;<span dangerouslySetInnerHTML={{ __html: about }} /></p>

                <p className='wallet-data'><span><b>Wallet</b></span>:&nbsp; <div className="account-wallet profile"><WalletLink/></div></p>

                 <Link style={ {"display": "inline-block","width": "100px","fontSize": "13px"} } className="button" to="/user/update" >Update</Link>

            </td>	
            </tr>	

        </table>
             
        <h6>Newest Posts</h6>
        {listingJsx}
    
        </Fragment>

    );
}

export default MyAccount;