import React, {Fragment, useState, useEffect} from "react";
import { useConnection } from '@solana/wallet-adapter-react';
import {fetchAllListingAccs, ListingAccount, fetchSingleListing, ListingClient} from "@enginefish/solist-sdk/build"
import { backend_api_url, getConnectionConfig, signMsgCurriedFn, getCurrentWalletPublicKey} from "./constants";
import ListingItem from "./ListingItem";
import { PublicKey } from '@solana/web3.js';


const LatestListings = (props : any) => {

	const { connection } = useConnection();
	const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)
	
	const fetchAllListings = async (txn_ids: string[]): Promise<any> => {


        //const config = getConnectionConfig();
            // Initialize signMsgFn by drawing out the private key first
        //const signMsgFn = await signMsgCurriedFn(props.provider);
        //const listingClient = new ListingClient(config.cluster, getCurrentWalletPublicKey(), signMsgFn);
        
        const promises = txn_ids.map(async txn_id => {
            const pubkey =  new PublicKey(txn_id);
           
            const listingAccount = await fetchSingleListing(connection, pubkey);
            return listingAccount;
        });

        const allListings = await Promise.all(promises)
        setListingJsx( allListings.map( (listingAccount) => <ListingItem key={listingAccount.id} hideContent={true} hideThumb={true} listingAccount={listingAccount}/>) );

	};


    const fetchAllListingsFromDb = async (): Promise<any> => {

        const params = {
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/postings/latest', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    fetchAllListings(data.postings)
                }
            });
	};

	useEffect(() => {
		fetchAllListingsFromDb();
	
	  }, []);

    return (
        <Fragment>
             
            <h5>Newest Posts</h5>			  
			{listingJsx}
	  </Fragment>
    );
}

export default LatestListings;