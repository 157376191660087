import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import TagLists from "./TagLists";
import { backend_api_url } from "./constants";
import { AsyncTypeahead } from 'react-bootstrap-typeahead'; // ES2015
import { useNavigate } from "react-router-dom";
import 'react-bootstrap-typeahead/css/Typeahead.css';

const TagSearch = (props : any) => {

    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<any[]>([]);
    const [searchInput, setSearchInput]  = useState<string>("");
    const navigate = useNavigate();

    const searchTagHandler = (event) => {
            // console.log(searchInput);
            event.preventDefault();
            if(searchInput != '') {
              navigate("/tag/"+searchInput.toLowerCase());
            }
    }

  
    const handleSearch = (query: string) => {
      setIsLoading(true);
      setSearchInput(query);

      fetch(backend_api_url + `api/v1/tags/search?q=` + query)
        .then((resp) => resp.json())
        .then(data => {
          setOptions(data.items);
          setIsLoading(false);
        });
    };
    const handleInputChange = (input : any, e : any) =>  {
        //console.log("value", input);
        setSearchInput(input);

      }
      const  handleChange = (selectedOptions : any) => {
        if(selectedOptions && selectedOptions.length) {
            setSearchInput(selectedOptions[0].name);
        }
      }
    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;

    return (
        <Fragment>

        <div className="form-group search-tag" id="frmSearchTags">
            
            <AsyncTypeahead
                filterBy={filterBy}
                id="async-example"
                isLoading={isLoading}
                labelKey="name"
                minLength={2}
                onSearch={handleSearch}
                options={options}
                placeholder="Explore a Tag"
                className="txtSearch"
                 //onInputChange={handleInputChange}
                onChange={handleChange}
                renderMenuItemChildren={(option: any) => (<span>{option.name}</span>)}
                />
            		  <a href="#" onClick={searchTagHandler} className="button">GO</a>
    
         </div>
        
        </Fragment>
    );
}

export default TagSearch;