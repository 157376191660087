import React, {useState, useEffect, Fragment} from 'react';
import { ArweaveStorage } from './stores';
import {Carousel} from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
 
import Slider from "react-slick";

interface AssetCellProps {
    assets : string[]
}

const AssetsCell = (props: any) => {
	const assets = props.assets;

	const arweaveStorage = new ArweaveStorage();

	const [data, updateData] = useState<string[] | any>();


	const fetchAllAssetListings = async (): Promise<any> => {
	
		const fetchedListings = await Promise.all(
			assets.map(async (asset: string) => {
				let assetUrl: string;
				try {
					assetUrl = await arweaveStorage.retrieveFile(asset);
				} catch (err: any) {
					return null;
				}
				
				return assetUrl;
			})
		);
		updateData(fetchedListings);	
	};

  useEffect(() => {
	if(props?.isEdit == 1) {
		updateData(assets);	
		//console.log('show delete');
		//console.log(assets);

	}else{
		fetchAllAssetListings();
	}
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
	adaptiveHeight : false, 
  };

  return (
	<Fragment>	
{/* 
	<Carousel showArrows={true} width="250px" dynamicHeight={false} className={props?.hideThumb ? "hide-thumb" : ""}>
	{
		data && data.map( (asset : string, index : number) => {
			return asset && <div key={(new Date()).getTime()}><img className='asset-image' alt=""  src={asset}/></div>;
		})
	}
	</Carousel> */}

	<div className={!data || data.length === 0 ? 'hide-slider' : ''}> 
		<Slider  {...settings}>

		{
			data && data.map( (asset : string, index : number) => {
				return asset && <div id="HomeBanner"><div key={(new Date()).getTime()} id="HomeBannerDiv" className="slider"><div className="homeBannerDetails"><img className='' alt=""  src={asset}/></div></div></div>;
			})
		}
	
 
		</Slider> 
	</div>
	</Fragment>
	);
}

export default AssetsCell;