import React, {Fragment, useRef, useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useConnection } from '@solana/wallet-adapter-react';
import * as solanaWeb3 from '@solana/web3.js';
import bs58 from 'bs58';
import { Keypair, PublicKey, LAMPORTS_PER_SOL, Connection } from '@solana/web3.js';
import {applications, Application, getUserSessionData, signMsgCurriedFn} from "./constants";
import { backend_api_url, cluster, getMinimumLamports, getCurrentWalletPublicKey, getConnectionConfig} from './constants';
import { ArweaveStorage } from './stores';
import {createInitListingTx, CreateListingParams, fetchSingleListing, createEditListingTx, EditListingParams, ListingAccount, uploadFileToArweaveViaBundlr, ListingClient, ClientInitListingParams, ClientEditListingParams} from "@enginefish/solist-sdk/build";
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import AssetsCell from "./AssetsCell";
import Modal from 'react-bootstrap/Modal';
import { getBalance, getPublisherNew} from "./constants";
import { Link } from "react-router-dom";

import { ProgressBar, ThreeCircles } from  'react-loader-spinner'
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import Dropzone from 'react-dropzone-uploader'

import { WithContext as ReactTags } from 'react-tag-input';
 
import { EditorState, ContentState } from 'draft-js'
import Editor from '@draft-js-plugins/editor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';
import { convertToHTML } from 'draft-convert';
import linkifyHtml from 'linkify-html';

import { SolanaWallet } from "@web3auth/solana-provider";
 
import "react-datepicker/dist/react-datepicker.css";
import 'react-quill/dist/quill.snow.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dropzone-uploader/dist/styles.css' 

interface UploadInfo {
	name: string;
	file: string;
    id: string;
}

const ListingForm = ( props : any ) => {

    props.setClassInfo('listingPostPg');
    //const suggestions = [];
    const tagKeyUp = (event) => {
        if(event.target.value.length > 1) {
            //console.log(event.target.value);

            fetch(backend_api_url + `api/v1/tags/search-new?q=` + event.target.value)
            .then((resp) => resp.json())
            .then(data => {
                setSuggestions(existing => [ ...data.items]);
            });

        }
    }
    const [suggestions, setSuggestions] = useState<{id,text}[]>([]);
   

    const KeyCodes = {
        comma: 188,
        enter: 13
      };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const [tags, setTags] = useState([]);
    
      const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
      };
    
      const handleAddition = tag => {
        setTags([...tags, tag]);
      };
    
      const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();
    
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
    
        // re-render
        setTags(newTags);
      };
    
      const handleTagClick = index => {
        ;//console.log('The tag at index ' + index + ' was clicked');
      };






    let navigate = useNavigate();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
 
    const [showPreview, setShowPreview] = useState(false);
    const handleClosePreview = () => setShowPreview(false);
    const handleShowPreview = () => setShowPreview(true);
    

    const applicationInput = useRef<HTMLSelectElement>(null);
    const titleInput = useRef<HTMLInputElement>(null);

    const expiringInput = useRef<HTMLInputElement>(null);
    const contentInput = useRef<any>(null);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    
    const [minSol, setMinSol] = useState<number>();
    const [solBalance, setSolBalance] = useState<number>(0);

    const [expirationDate, setExpirationDate] = useState<Date>();
    const [minDate, setMinDate] = useState<Date>(new Date());

    const [allFiles, setAllFiles] =  useState<File[]>([]);

    const [allFilesNew, setAllFilesNew] = useState<UploadInfo[]>([])

    const [contentVal, setContentVal] = useState<string>('');

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [currentListing, setCurrentListing] = useState<PublicKey>();
    const [currentEditAssets, setCurrentEditAssets] = useState<string[]>([]);
    const [currentEditAssetsInfo, setCurrentEditAssetsInfo] = useState<any>([]);

    const { connection } = useConnection();
    const [tagK, setTagK] = useState(0);
    const [assetJsx, setAssetJsx] = useState<JSX.Element | null>(null)
    const [currentUsername, setCurrentUsername] = useState('');
    const [currentListingAccount, setCurrentListingAccount] = useState<ListingAccount>()

    let params = useParams();

    const checkAccount = () => {
        const userData = getUserSessionData();
        if(!userData) {
            navigate("/");
        } 
    }

    const getKeypairFromSeed = (seed: string): Keypair => {
        const seedBytes = bs58.decode(seed);
        return Keypair.fromSeed(seedBytes);
    }; 

    const formatDate = (inputDate) => {
        let date, month, year;
      
        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        year = inputDate.getFullYear();
      
          date = date
              .toString()
              .padStart(2, '0');
      
          month = month
              .toString()
              .padStart(2, '0');
      
        return `${year}-${month}-${date}`;
      }

    const fetchEditListing = async (): Promise<any> => {
        const txnId : string = params.txnId as string;
		const pubkey =  new PublicKey(txnId);
    
        setCurrentListing(pubkey);
        
        const pubWal = getCurrentWalletPublicKey();

        const listingAccount = await fetchSingleListing(connection, pubkey);
        
        if(!pubWal) {
            toast.warn("Login Wallet to Edit Listing");
        }else if (listingAccount.publisher === pubWal.toBase58()) {
            //console.log("Matched, can edit");
            //console.log(listingAccount);

            if(titleInput && titleInput.current)
                titleInput.current.value = listingAccount.header;
            if(contentInput && contentInput.current) {
                setContentVal(listingAccount.content);
                const processedHTML = DraftPasteProcessor.processHTML(listingAccount.content);
                const contentState = ContentState.createFromBlockArray(processedHTML);
                setEditorState(EditorState.createWithContent(contentState));
            }
             
            setSelectedTags( (existing : string[]) => [...listingAccount.tags ] );

            setTags(listingAccount.tags.map(tagName => 
                {   
                    const newData = {}
                    newData["id"] = tagName;
                    newData["text"] = tagName;

                    return newData;
                }
                ));
             

            setTagK(new Date().getTime());

            if(listingAccount.expiringAt && listingAccount.updatedAt ) {
                setExpirationDate( (new Date(listingAccount.expiringAt * 1000))); // 30-Dec-2011);

            }
            setIsEdit(true);
            setCurrentEditAssets(listingAccount.assets);
            setAssetJsx(<div className="form-group" style={ {"textAlign" : "left"} }><AssetsCell assets={listingAccount.assets}></AssetsCell></div>);
            setCurrentListingAccount(listingAccount);
            fetchAllAssetListings(listingAccount.assets);
            console.log(listingAccount);
        }
	};
	const arweaveStorage = new ArweaveStorage();

    const fetchAllAssetListings = async (assets): Promise<any> => {
	
		const fetchedListings = await Promise.all(
			assets.map(async (asset: string) => {
				let assetUrl: string;
				try {
					assetUrl = await arweaveStorage.retrieveFile(asset);
				} catch (err: any) {
					return null;
				}
				
                setCurrentEditAssetsInfo(existing => [...existing, { 'url' :  assetUrl, 'id' : asset, 'encoded' : ''}]);

				return assetUrl;
			})
		);
        setAllFiles([]);

        for(var i in fetchedListings) {
            const imageDataUrl = fetchedListings[i];
            fetch(imageDataUrl).then(res => {
                res.arrayBuffer().then(buf => {

                    const typeFile = (imageDataUrl.split(';')[0].split(":")[1]);
                    const file = new File([buf], 'file-'+Math.random(), { type: typeFile });

                    setAllFiles(existing => [...existing, file])
                })
            });
        }
        //updateData(fetchedListings);	
	};

    const getLamPort = async () => {
        const minLam = await getMinimumLamports(connection);
        const minSols = minLam / LAMPORTS_PER_SOL;
        setMinSol(minSols);
    }

    useEffect(() => {
        if(params.txnId) {
            fetchEditListing();//, 5000);
        }
        getLamPort();
        //checkAccount();
        // /handleShow();
    }, []);
    
    


    const isWalletReady = () : boolean => {

        const walletPubKey = getCurrentWalletPublicKey();

		return (
            walletPubKey != null
		);
	};
    

    const updatePayloadToBackend = (payload : ClientEditListingParams, txnId : string, days : number, signature : string) => {
        
        const userData = getUserSessionData();

        const params = {
            token: userData.token,
            secret: userData.secret,
            txnId: txnId,
            tags : payload.tags,
            cluster: cluster,
            title: titleInput.current!.value,
            content: payload.content,
            assets: payload.assets,
            //expirationDays: days,
            //expireDate: formatDate(expirationDate),
            signature: signature
            //wallet: publicKey ? publicKey.toBase58() : '',
        }

        if(expirationDate) {
            params['expireDate'] = formatDate(expirationDate);
            params['expirationDays'] = days;
        }
 
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/postings/update', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    navigate("/post/"+txnId);
                }
            });
    }
    const submitPayloadToBackend = (payload : ClientInitListingParams, txnId : string, signature : string) => {

        const userData = getUserSessionData();

        const params = {
            token: userData.token,
            secret: userData.secret,
            txnId: txnId,
            tags : payload.tags,
            cluster: cluster,
            title: titleInput.current!.value,
            content: payload.content,
            assets: payload.assets,
            //expireDate: formatDate(expirationDate),
            signature: signature

            //wallet: publicKey ? publicKey.toBase58() : '',
        }

        if(expirationDate) {
            params['expireDate'] = formatDate(expirationDate);
            params['expirationDays'] = payload.expirationDays;
        }

        //console.log(params);


        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/postings/add', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    navigate("/post/"+txnId);
                }
            });
    }

    const getDateDifferenceFromExpiration = () => {
        //console.log(expirationDate?.toLocaleDateString('en-ca'));

        if(!expirationDate) {
            return null;
        }

        const dt1 = new Date();
        const dt2 = expirationDate;
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));


    }

    const loadWalletBalance = async () => {

        const userData = getUserSessionData();
        if(userData) {
            
            if(userData.wallet) { 

                const pubkey =  new PublicKey(userData.wallet);

                const balance = await getBalance(pubkey, connection);
                setSolBalance(balance);
                return (balance);
            }
        }
        return 0;
    }
 
   
  
    const formSubmitHandler = async (event: any) => {

        event.preventDefault();
        
        let errMsg : string;
        
        if(getUserSessionData() == null || props.provider == null) {
            props.loginWeb3Auth();
            return;
        }


		if (isWalletReady()) {

			try {

                
                if(!isEdit ) {
                    const balance = await loadWalletBalance();
                    if(minSol > balance) {

                        showToastError("Needs more SOLs to be able to create this posting");
                        return;
                    }
                }
                handleShow();

                //const tag: string[] = selectedTags;

                const tag : string[] = tags.map( tagInfo => tagInfo.text);

                const publisher = await getPublisherNew(props.provider);

                const web3authProvider = await props.web3auth.connect();
 
                const config = getConnectionConfig();
                 // Initialize signMsgFn by drawing out the private key first
                const signMsgFn = await signMsgCurriedFn(props.provider);
                const listingClient = new ListingClient(config.cluster, getCurrentWalletPublicKey(), signMsgFn);
                
                // const solanaWallet = new SolanaWallet(web3authProvider);
               
                const allContents = allFilesNew.map(  file => {
                     return { content: file.file };
                }); 
                 
                if(allContents.length === 0) {
                    handleClose();
                    showToastError("Image is required.");
                    return;
                }
                
                const options = { defaultProtocol: 'https', attributes: {
                    rel: 'nofollow'
                  } };
                const newContent = linkifyHtml(convertToHTML(editorState.getCurrentContent()), options);

                if(isEdit) {
                
                    const txnId : string = params.txnId as string;
                   
                    let newContents = [];
                    let isFound  = false;
                    let content = '';
                    for(var i in allContents) {
                        content = allContents[i].content;
                        isFound = false;
                        //we check the current edit assets
                        for(var j in currentEditAssetsInfo) {
                            if(currentEditAssetsInfo[j].url == content) {
                                newContents.push ( {'arweaveTxId' : currentEditAssetsInfo[j].id} );
                                isFound = true; 
                                break;
                            }
                        }
                        if(!isFound) {
                            newContents.push ( {'content' : content} );
                        }
                    }

                    
                    let payload : ClientEditListingParams = {
                        header: titleInput.current!.value,
                       content: newContent,
                       assets: newContents,
                       tags: tag,
                   };
                   const days = getDateDifferenceFromExpiration();

                   if(expirationDate) {


                        const newExpiringAt = Math.floor(Date.now() / 1000) + (60 * 60 * 24 * days);
                       payload = {
                           expiringAt: newExpiringAt,
                           header: titleInput.current!.value,
                           content: newContent,
                           assets: newContents,
                           tags: tag,
                       }   
                   }
                   

                    const signature = await listingClient?.editListing(currentListing, payload);


                    await connection.confirmTransaction(signature);
                    //console.log(signature);
                
                    updatePayloadToBackend(payload, txnId, days, signature);                   
                    // toast.success("Post Edited Successfully", {
                    //     className : "success-toast"
                    // });
                    props.loadSolBalance();
 

                }else{
                   
                    const listingAccSeed = Keypair.generate().publicKey.toBase58();
                    const listingAcc = getKeypairFromSeed(listingAccSeed);
                
                    const expiringNumDays = getDateDifferenceFromExpiration();

 
                        
                    let payload : ClientInitListingParams = {
                        listingAcc,
                        application: new PublicKey(applicationInput.current!.value),
                        header: titleInput.current!.value,
                        content: newContent,
                        assets: allContents,
                        tags: tag,
                    } 

                    if(expirationDate) {
                        payload = {
                            listingAcc,
                            application: new PublicKey(applicationInput.current!.value),
                            header: titleInput.current!.value,
                            content: newContent,
                            expirationDays: expiringNumDays,
                            assets: allContents,
                            tags: tag,
                        }   
                    }

                            
                    const signature = await listingClient?.initListing(payload);

                    await connection.confirmTransaction(signature);
                 
                    submitPayloadToBackend(payload, listingAcc.publicKey.toBase58(), signature);

                    // toast.success("Posted Successfully", {
                    //     className : "success-toast"
                    // });
                    props.loadSolBalance();

                }
                handleClose();
			} catch (err: any) {
                console.log(err)
				errMsg = (err as Error).message;
                showToastError(errMsg);
                handleClose();
			}
		}else{
            showToastError("Please connect your wallet");

        }
	};  
      const displayDate = (dateUpdate : number) => {
        if(dateUpdate) {
            const date = new Date(dateUpdate * 1000);
            return date.toLocaleDateString('en-ca').replaceAll('-', '.');
        }
        return "";
      }

      const [previewCreatedDate, setPreviewCreatedDate] = useState<string>();
      const [previewExpirationDate, setPreviewExpirationDate] = useState<string>();
      const [previewTags, setPreviewTags] = useState<string[]>();
      const [previewTitle, setPreviewTitle] = useState<string>();
      const [previewContent, setPreviewContent] = useState<string>();
      const [previewImageJsx, setPreviewImageJsx] = useState<JSX.Element[] | null>(null);
      const [historyJsx, setHistoryJsx] = useState<JSX.Element[] | null>(null);

      const generateRandomKey =(index) => {
        return new Date().getTime() + index;
      }
      
      const previewListing = (e) => {
        e.preventDefault();

        const userData = getUserSessionData();
        if(userData) { 
            setCurrentUsername(userData.username); 
        }
        const options = { defaultProtocol: 'https' };

        const newContent = linkifyHtml(convertToHTML(editorState.getCurrentContent()), options);

        if(isEdit) {
            const unixTime = currentListingAccount.createdAt as number;
            const date = new Date(unixTime * 1000);
            setPreviewCreatedDate(date.toLocaleDateString('en-ca').replaceAll('-', '.'));
            if(expirationDate) {
                setPreviewExpirationDate(expirationDate.toLocaleDateString('en-ca').replaceAll('-', '.'));
            }else{
                setPreviewExpirationDate("N/A");
            }
            
            if(allFilesNew.length > 0) {
                setPreviewImageJsx(allFilesNew.map( (image, index) => <img key={generateRandomKey(index)} className="asset-image" src={image.file} /> ));
            }else{
                setPreviewImageJsx([assetJsx]);
            }
            
            setPreviewTitle(currentListingAccount.header);
            setPreviewContent(newContent);//currentListingAccount.content);

            const historyItemJsx  = currentListingAccount.history.reverse().map( (historyData, index) => <li className="history-data" key={index}><a href="">Updated {displayDate(historyData.updatedAt)}</a></li>);

            setHistoryJsx([...historyItemJsx, <li key={generateRandomKey(0)}><a href="">Created {displayDate(currentListingAccount.createdAt as number)}</a></li>]);
            //
            setPreviewTags(selectedTags);

        }else{
            const date = new Date();
            setPreviewCreatedDate(date.toLocaleDateString('en-ca').replaceAll('-', '.'));
            if(expirationDate) {
                setPreviewExpirationDate(expirationDate.toLocaleDateString('en-ca').replaceAll('-', '.'));
            }else{
                setPreviewExpirationDate("N/A");
            }
            setPreviewTitle(titleInput.current.value);
            setPreviewContent(newContent);
 
            if(allFilesNew.length > 0) {
                setPreviewImageJsx(allFilesNew.map( (image, index) => <img key={generateRandomKey(index)} className="asset-image" src={image.file} /> ));
            }
            setHistoryJsx([<li><a href="">Created {date.toLocaleDateString('en-ca').replaceAll('-', '.')}</a></li>]);

            const tag : string[] = tags.map( tagInfo => tagInfo.text);

            setPreviewTags(tag);

        }
        handleShowPreview();
    } 
      // specify upload params and url for your files
         const getUploadParams = ({ meta }) => { return { url: backend_api_url + 'api/v1/users/upload' } }
        

        const validate = (file) => {
             
            const fileMeta = file.meta;    
            
            if (fileMeta.height < 300 || fileMeta.width < 300) {
                return `Image height and width must be at least 300px`;
            }
            return null;
        } 
        // called every time a file's `status` changes
        const handleChangeStatus = ({ meta, file }, status) => { 
 
            if(status === 'removed') {
                setAllFilesNew(
                    allFilesNew.filter(a =>
                      a.id !== meta.id
                    )
                  );
            }else if(status === 'done') {
                console.log(meta);

                if(meta.height < 300 || meta.width < 300) {
                    showToastError("Image size must be at least 300x300");
                    return;
                } else {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (event) => {
                        const imageString = event.target?.result as string;
    
                        setAllFilesNew(existing => [...existing, { name: file.name, file: imageString, id: meta.id}]);

                    };
                }
            }
        }
        
        // receives array of files that are done uploading when submit button is clicked
        const handleSubmit = (files, allFiles) => {
            //console.log(files.map(f => f.meta))
            allFiles.forEach(f => f.remove())
        } 
        const [editorState, setEditorState] = React.useState(
              //move focus to the end. 
              () => {
    
                const processedHTML = DraftPasteProcessor.processHTML('');
                const contentState = ContentState.createFromBlockArray(processedHTML);
                return EditorState.createWithContent(contentState);
              }
          );
     
          const linkifyPlugin = createLinkifyPlugin();

          const cursorMove = () => {
            
            const currentState = editorState;
            var selectionState = editorState.getSelection()
            setEditorState(EditorState.forceSelection(currentState, selectionState))

          }
          
    
    const showToastError = (message) => {
       
          toast.dismiss();
        setTimeout(function(){
            toast.error((<Fragment>{message}<div className="toast-close-section"><button className="link-button toast-close">close message</button></div></Fragment>),{
                autoClose: false,
                //closeButton: false
            });
        }, 500);
    } 
 
    return (
        <Fragment>
         
        <Form id="frmCreatPost" onSubmit={formSubmitHandler}>

        <h2>{isEdit ? "Edit Post" : "Add Post"}</h2>

            <div className="form-group" style={{"textAlign" : "left", "marginBottom" : "10px"}}>
                <Dropzone
                    getUploadParams={getUploadParams}
                    validate={validate}
                    onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    initialFiles={allFiles}
                    accept="image/*"
                    
                    />
            </div>
 
		  <label>Title</label>

		  <div className="br"></div>

		  <input type="text" className="form-control" required ref={titleInput}/>

		  <div className="br"></div>
	
		  <label>Description</label>

		  <div className="br"></div>

 
            <div className='solist-wysywg' onClick={cursorMove}>
        <Editor
            editorState={editorState}
            onChange={setEditorState}
            plugins={[linkifyPlugin]}
            
                ref={contentInput}
            />
          </div> 

		  <div className="br"></div>

		  <label>Tags (comma seperated, recommended)</label>

		  <div className="br"></div>

           <ReactTags
                    tags={tags}
                    suggestions={suggestions}
                    delimiters={delimiters}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    handleTagClick={handleTagClick}
                    inputFieldPosition="bottom"
                    inputProps = {{
                         onKeyUp: tagKeyUp
                    }}
                    classNames={{
                        tagInputField: 'form-control1'
                    }}
                    />

		  <div className="br"></div>

		  <label>Expiry Date (optional)</label>

		  <div className="br"></div>

           <DatePicker className="form-control"  minDate={minDate} ref={expiringInput} selected={expirationDate} onChange={(date) => setExpirationDate(date)} />
		  <div className="br"></div>

		  <a href="#" className="preview" onClick={previewListing}>Preview Listing</a>
 
		  {/* <a href="#" className="button" >Upload Post</a> */}
		  

          <button className="link-button"  type="submit">                    
            Upload &amp; Post Listing
         </button>	 

		 <p>Pay {minSol} Sols for transactions fees</p>

         

            <Form.Group as={Row} className="mb-3" controlId="formBasicEmail" style={ {"display": "none"} }>
                <Form.Label>application</Form.Label>
                <select aria-label="Default select example" ref={applicationInput}>
                    {applications.map( (app : Application) => <option key={app.publicKey} value={app.publicKey}>{app.name}</option> )}
                </select>
            </Form.Group>
               
        
          
      </Form>

      {/* <ToastContainer position="top-center"/> */}
<Modal className="modal-preview" show={showPreview} onHide={handleClosePreview}
  size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title>PREVIEW</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
                <div className="view-listing row">
                <div className="form-group">
                    <h3>{previewTitle}</h3>
                </div>   
                 
                <div className="form-group extra-info">
                    <div className="owner">
                        by: {currentUsername}&nbsp;|&nbsp;Created:&nbsp;{previewCreatedDate}&nbsp;|&nbsp;Expires:&nbsp;{previewExpirationDate}&nbsp;|&nbsp;

                        Tags:&nbsp;{previewTags  && previewTags.map( (tag, index) =>  <Link className="tag" key={index} to={"/tag/" + tag}>{tag}</Link> )}

                    </div>
                </div> 
                <div className="form-group">
                   {previewImageJsx}
                </div>
                <div className="form-group dynamic-html">
                   <span dangerouslySetInnerHTML={{ __html: previewContent }} />
                </div> 
                <div className="form-group">
                    <label>History</label>
                    <ul>
                        {historyJsx}
                    </ul>
                </div>
            </div> 
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>
    
<Modal className="modal-processing" show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton={false}>
            <Modal.Title>Processing...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <div className="form-group"> */}

                <div className="custom-loader"></div>
{/*                 
                <ProgressBar
                    height="100%"
                    width="100%"
                    ariaLabel="progress-bar-loading"
                    wrapperStyle={{}}
                    wrapperClass="progress-bar-wrapper"
                    borderColor = '#000000'
                    barColor = '#808080'
                    /> */}
                {/* </div> */}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 
      </Fragment>

    );
}

export default ListingForm;