import { Connection, PublicKey, clusterApiUrl, RpcResponseAndContext, SignatureResult, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { FC, useEffect, useRef, useState } from "react";
import { useConnection } from '@solana/wallet-adapter-react';
 

const Airdrop = (props: any) => {
	 

    const { connection } = useConnection();

    const publicKey = useRef<HTMLInputElement>(null);

    //const [ publickey, setpublickey] = useState<string>();
    const [ sol, setSols ] = useState(2);
    const [ txid, setTxid ] = useState<string | null>(null);
    const [ slot, setSlot ] = useState<number | null>(null);
    const [ balance, setBalance ] = useState(0);

    // Retrieve the balance when mounting the component
    // useEffect( () => {
    //     const pubkey =  new PublicKey(publickey);

    //     connection.current.getBalance(pubkey).then(setBalance);
    // }, [pubkey]);


    function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault();
        const pubkey =  new PublicKey(publicKey.current.value);

        const AIRDROP_AMOUNT = sol * LAMPORTS_PER_SOL; // 1 SOL 

        pubkey && connection.requestAirdrop(pubkey, AIRDROP_AMOUNT)
        .then((id: string) => { 
            console.log(`Transaction ID ${id}`);
            setTxid(id);
            connection.confirmTransaction(id)
            .then((confirmation: RpcResponseAndContext<SignatureResult>) => {
                console.log(`Confirmation slot: ${confirmation.context.slot}`);
                setSlot(confirmation.context.slot);
                connection.getBalance(pubkey).then(setBalance);
            });
        })
        .catch(console.error);
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSols(parseInt(event.target.value));
    }

  return (

<div>
            <p className="p15">&nbsp;</p>
            <form onSubmit={handleSubmit}>
                <label>Public Key to receive airdrop</label><br/>
                <input type="text" ref={publicKey} className="input-text"  /><br/>
                <label>SOL to request</label><br/>
                <input type="number" value={sol} onChange={handleChange} className="input-text" /><br/>
                <input type="submit" value="Request airdrop" className="input-submit" />
            </form>
            <p className="p15">&nbsp;</p>
            <hr/>
            { txid ? <p>Transaction: {txid}</p> : null }
            { slot ? <p>Confirmation slot: {slot}</p> : null }
            <hr/>
            <p>Your current balance is: {balance}</p>
        </div>
	);
}

export default Airdrop;