import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { backend_api_url, cluster, getUserSessionData} from './constants';
import { ToastContainer, toast } from 'react-toastify';
import ThreadItem from "./ThreadItem";
import { fetchSingleListing} from "@enginefish/solist-sdk/build";
import {  PublicKey } from '@solana/web3.js';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import AssetsCell from './AssetsCell';
import MessageListingItem from './MessageListingItem';

import 'react-toastify/dist/ReactToastify.css';


const MessageUser = (props : any) => {

    props.setClassInfo('messagesReplyPg');

    const { connection } = useConnection();

    const [currentUsername, setCurrentUsername] = useState<string>("");
    const [hasUserInfo, setHasUserInfo] = useState(false);

    const [username, setUsername] = useState<string>("");
    const [messageJsx, setMessageJsx] = useState<JSX.Element[] | null>(null)
    const [itemJsx, setItemJsx] = useState<JSX.Element | null>(null)

    const [currentMessageId, setCurrentMessageId] = useState<number>(0);

    const params = useParams();
    const messageInput = useRef<any>(null);

    const showToastError = (message) => {
        toast.error((<Fragment>{message}<div className="toast-close-section"><button className="link-button toast-close">close message</button></div></Fragment>),{
            autoClose: false,
            closeButton: false
          });
    }

    const sendMessageHandler = () => {

        if(!messageInput.current!.value) {
            showToastError("Message is required");
            return false;
        } 

        const userData = getUserSessionData();

        const paramsList = {
            token : userData ? userData.token : '',
            secret : userData ? userData.secret : '',
            to : username,
            message: messageInput.current!.value,
            txn_id : params.txnId  ? params.txnId : '',
            //is_edited: currentMessageId > 0 ? 1 : 0,
            messageId: currentMessageId
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(paramsList)
        };
        fetch(backend_api_url + 'api/v1/messages/add', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                   if(currentMessageId > 0) {
                    // toast.success("Message edited successfully", {
                    //     className : "success-toast"
                    // });

                   }else{
                    // toast.success("Message sent successfully", {
                    //     className : "success-toast"
                    // });
                   }  
                   messageInput.current!.value = '';
                   fetchAllMessages();
                   setCurrentMessageId(0);
                }
            });

    }

    const fetchAllMessages = () => {

        const userData = getUserSessionData();

        const paramsList = {
            token : userData ? userData.token : '',
            secret : userData ? userData.secret : '',
            to : params.userName,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(paramsList)
        };
        fetch(backend_api_url + 'api/v1/messages/all', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    setMessageJsx( data.message.map( (messageData : any) => <Fragment key={messageData.id} > { messageData.postings_id > 0 ?  <MessageListingItem listing={messageData}/> :  <ThreadItem  editMessage={editMessage} messageData={messageData} /> } </Fragment>) );

                    localStorage.setItem("unread", ""+Math.random());
                }
            });

    }

    const editMessage = (id) => {
        
        //console.log("Edit Message" + id);


        const userData = getUserSessionData();

        const paramsList = {
            token : userData ? userData.token : '',
            secret : userData ? userData.secret : '',
            id : id,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(paramsList)
        };
        fetch(backend_api_url + 'api/v1/messages/info', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    console.log(data.content);
                    setCurrentMessageId(data.id);
                    messageInput.current.value = data.content;
                }
            });
    }

    const checkUser = () => {

        const userData = getUserSessionData();
        if(userData) {
            setHasUserInfo(true);
            setCurrentUsername(userData.username);

            fetchAllMessages();
            props.loadHandler();
        }

    }

    const fetchPosting = async () : Promise<any> => {
            const txnId : string = params.txnId as string;
            const pubkey =  new PublicKey(txnId);
     
            const listingAccount = await fetchSingleListing(connection, pubkey);
    
            setItemJsx(<MessageListingItem listing={listingAccount}/>);
        
    }

    useEffect(() => {
        setUsername(params.userName as string);

		checkUser();
	
        if(params.txnId) {
            fetchPosting();//, 5000);
        }

	  }, [params.userName, params.txnId, '']);
 
    return (
        <Fragment>

            <h2>Messages</h2>

            <div className="messagesTrail">
                <Link to="/messages">Messages</Link> &gt;&gt; {username}
            </div>
            <br />

            {/* <p className="date">---Aug 25, 2022---</p> */}

            <div  id="frmSendMsg"  >
                
                    {messageJsx}
                    {/* {itemJsx} */}
 
                <input type="text" id="txtWrite" name="txtWrite" placeholder="Write a message" ref={messageInput}/>
                <a href="#" className="button" onClick={sendMessageHandler}>SEND</a>
            </div>
            {/* <ToastContainer position="top-center" /> */}
            
            <div className='col-xs-12'>
                
                <main className="msger-chat">
                    
                    
                </main>
            
            </div>
        </Fragment>

    );
}

export default MessageUser;