import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import { getBalance, getPublisher, SOLIST_PROGRAM_ID} from "./constants";
import { PublicKey , Keypair, LAMPORTS_PER_SOL} from "@solana/web3.js";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { ToastContainer, toast } from 'react-toastify';
import { getCurrentWalletPublicKey} from './constants';

import WalletLink from "./WalletLink"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SystemProgram, Transaction } from '@solana/web3.js';
import LedgerItem from "./LedgerItem"; 

import { SolanaWallet } from "@web3auth/solana-provider";

const WalletInfo = (props : any) => {
 
    props.setClassInfo('signUpPg walletPg');

    const amountInput = useRef<HTMLInputElement>(null);
    const addressInput = useRef<HTMLInputElement>(null);
 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [solParams, setSolParams] = useState(null);

    const { connection } = useConnection();

 
    const [ledgerJsx, setLedgerJsx] = useState<JSX.Element[] | null>(null)


    const checkTxn = async () => {
        const userData = JSON.parse(localStorage.getItem("userData")  as string);
        if(userData) {
            
            if(userData.wallet) {
                const pubkey =  new PublicKey(userData.wallet);
                
                let isCont = false;
                let lastSignature = "";
                let allTransactions = [];
                do{
                    isCont = false;
                    let parameter = {};
                    parameter['limit'] = 1000;
                    if(lastSignature) {
                        parameter['before'] = lastSignature;
                    }
                    const signatures = await connection.getSignaturesForAddress(pubkey, parameter,  "finalized");
                    // console.log(signatures.length);
                    if(signatures.length > 0) {
                         lastSignature = signatures[signatures.length - 1].signature;
                         isCont = true;
                        for(var i in signatures) {
                            allTransactions.push(signatures[i]);
                        }
                    }
                }while(isCont);
                // console.log(allTxns);
                // console.log(allTransactions);
  
                setLedgerJsx( allTransactions.map( (transaction) => <LedgerItem key={transaction.signature}  signature={transaction.signature}/>) );

                // getTransaction
            }
        }
    }

    
  
    useEffect(() => {
        checkTxn();
    }, []);

    const sendSolNow = async () => {
            // console.log(solParams);
            handleClose();
            // alert('Send sol now');

            
            const amount = solParams['amount'] * LAMPORTS_PER_SOL; // hardcoded to 1 SOL for now
        
            const solanaWallet = new SolanaWallet(props.provider);

            const pubWal = getCurrentWalletPublicKey();
            const blockhash = (await connection.getRecentBlockhash("finalized")).blockhash;
            const TransactionInstruction = SystemProgram.transfer({
            fromPubkey: solParams['fromWallet'],
            toPubkey: solParams['toWallet'],
            lamports: amount,
            });
            const transaction = new Transaction({
            recentBlockhash: blockhash,
            feePayer: pubWal,
            }).add(TransactionInstruction);
            
            
            const { signature } = await solanaWallet.signAndSendTransaction(transaction);
    
            console.log(signature);
            // toast.success("Transferred Successfully", {
            //     className : "success-toast"
            // });
            props.loadSolBalance();

    }

    const showToastError = (message) => {
        toast.error((<Fragment>{message}<div className="toast-close-section"><button className="link-button toast-close">close message</button></div></Fragment>),{
            autoClose: false,
            closeButton: false
          });
    } 

    const formSubmitHandler = async (e) => { 
        e.preventDefault();


        const params = {
            amount: amountInput?.current.value,
            address: addressInput?.current.value.trim()
        }

        const userData = JSON.parse(localStorage.getItem("userData")  as string);
        if(userData) {
            
            if(userData.wallet) {
                const pubkey =  new PublicKey(userData.wallet);
                const balance = await getBalance(pubkey, connection);
                //console.log(balance);
                //console.log("Send Sols", params);

                if(balance < parseFloat(params.amount)) {
                    showToastError("Amount is greater the wallet balance.");
                    return false;
                }

                //check if the address is correct
                try{
                    // code for error testing
                    const accountInfo = await connection.getAccountInfo(new PublicKey(params.address));
                    //console.log(accountInfo);

                    params['accountInfo'] = accountInfo;
                    params['fromWallet'] = new PublicKey(userData.wallet);
                    params['toWallet'] = new PublicKey(params.address);


                    setSolParams(params);
                    //send money now here
                    handleShow();

                }
                catch (e) {
                // code for error handeling
                           // console.log(e)
                    showToastError("Invalid Address.");
                    return false;
                }
               
            } 
        }

        

        //console.log(props.solBalance);
    } 
 
    return (
        <Fragment>

        <h2>App Wallet</h2>

        <p className="wallet-data"><span><b>Wallet address </b></span>:&nbsp; {<div className="account-wallet"><WalletLink isExternal={true}/></div>}</p>

        <p><span><b>SOLs </b></span>:&nbsp; {props.solBalance.toFixed(4)}</p>

        <div className="signup">
        <Form name="frmSignUp" id="frmSignUp" onSubmit={formSubmitHandler} >
                
            <h5>Send SOLs</h5>

            <div className="formFields">

                <label className="email">Amount:</label>
                <input  type="text" id="txtAmount" required ref={amountInput}/>

                <div className="br"></div>

                <label>To Address:</label>
                 <input  type="text" id="txtAddress" required ref={addressInput}/>
   
            </div>
             
            <button className="link-button button" type="submit">SEND</button>

        </Form>	

 

        </div>

        <h6>Transactions</h6>

        <div className="scroll">
            
        <table style={ {"border" : "0", "padding" : "0px", "borderSpacing" : "0px", "borderCollapse" : "separate"} } className="transactions"  width="100%">
            <tr>
            <th>Date</th>
            <th>Transaction</th>
            <th>From</th>
            <th>To</th>
            <th>Amount</th>
            <th>Balance</th>  
            </tr>	
            {ledgerJsx}
        
        </table>
        </div>

            {/* <ToastContainer position="top-center" /> */}
 
            <Modal className="modal-registration" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>SIGNUP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <div className="form-group">
                         Are you sure you want to send SOLs to this address? 
                     </div> 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={sendSolNow}>
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </Fragment>

    );
}

export default WalletInfo;