import React, {Fragment, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom'; 
import Moment from 'react-moment';

const MessageItem = (props: any) => {

    const item = props.messageData;

   

    let msgClassName = "msg left-msg message-item";

    if(item.has_unread === 1) {
        msgClassName = "msg left-msg message-item";
    }
    const navigate = useNavigate(); 
    const clickHandler = () => {
      
        navigate("/message/"+item.username_display);
    }


    return (
        <Fragment>

            <tr style={ {"verticalAlign" : "top"}}  onClick={clickHandler}>
                <td width="90" align="left" className="msg-img-avatar"><img alt="" src={item.avatar_display ? item.avatar_display : '/avatar.png'}/></td>
                <td align="left">
                <div>
                    <a href="#">{item.username_display}</a>
                    <span>
                    <Moment local format="YYYY.MM.DD hh:mm a" >
                      {item.posted_converted_date}
                     </Moment>
                     </span>
                </div>
                    
                <p>{item.is_sender === 1  ? 'You': (item.is_received_read === 1 ? '' : <b>Unread</b> )} - { item.last_message }</p>
                </td>	
            </tr>	
        </Fragment>

    )

}

export default MessageItem;