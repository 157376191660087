import React, {Fragment, useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import { Routes, Route, useParams } from "react-router-dom";
import { fetchSingleListing} from "@enginefish/solist-sdk/build";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {  PublicKey } from '@solana/web3.js';
import AssetsCell from './AssetsCell';
import { ListingAccount} from "@enginefish/solist-sdk/build"
import {backend_api_url, cluster} from "./constants";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import linkifyHtml from "linkify-html";
import { convertToHTML } from 'draft-convert';

const ViewListing = (props : any) => {
    
    props.setClassInfo('listingPg');

    let params = useParams();
    let navigate = useNavigate();

    const { connection } = useConnection();

    const [title, setTitle] =  useState<string>("");
    const [contentVal, setContentVal] = useState<string>('');

    const [username, setUsername] = useState<JSX.Element | null>(null)

    const [usernameJsx, setUsernameJsx] = useState<JSX.Element | null>(null)
    const [tags, setTags] = useState<string[]>([])
    const [listingAccount, setListingAccount] = useState<ListingAccount | null>(null);
    const [assetJsx, setAssetJsx] = useState<JSX.Element | null>(null)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [hasSignature, setHasSignature] = useState(false);
    const [signatureList, setSignatureList] = useState([]);

    const [histories, setHistories] = useState([]);

    const fetchOwnerInfo = (row: ListingAccount) => {
        
        fetch(backend_api_url + 'api/v1/users/info?wallet='+row.publisher)
        .then(response => response.json())
        .then(data => {
            if(data.id > 0) {
                setUsername(data.username);
                setUsernameJsx(<Link to={"/user/" + data.username}>{data.username}</Link>);
            }else{
                setUsernameJsx(<span>N/A</span>);

            }
        });

        //return <Link to={"/user/" + 'username'}>Daniel</Link>;
    }

    const fetchPostingInfoHistory = async (txnId) => {
        const params = {
            txnId: txnId,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/postings/history', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    console.log(data.histories);
                    if(data.histories.length > 0) {
                        setHasSignature(true);
                        setSignatureList(existing => [...data.histories]);
                    }
                }
            });
    }
    const fetchEditListing = async (): Promise<any> => {
        const txnId : string = params.txnId as string;
		const pubkey =  new PublicKey(txnId);
 
        const history = await fetchPostingInfoHistory(txnId);

        const listingAccount = await fetchSingleListing(connection, pubkey);

            //console.log(listingAccount);
            setTitle(listingAccount.header);
            setContentVal(listingAccount.content);
            setTags(listingAccount.tags);
            setListingAccount(listingAccount);
            fetchOwnerInfo(listingAccount);	
            setAssetJsx(<AssetsCell assets={listingAccount.assets}></AssetsCell>);
	};

    useEffect(() => {
        if(params.txnId) {
            fetchEditListing();//, 5000);
        }
    }, []);

    const displayCreatedDate = (row: ListingAccount | null) => {
        if(row) {
            const unixTime = row.createdAt as number;
            let date = new Date(unixTime * 1000);

    
            if( row.history.length > 0 ) {
                date = new Date(row.updatedAt * 1000);
            } 
            
            return date.toLocaleDateString('en-ca').replaceAll('-', '.');
        }
        return "";
    }

    const expiringAtCol = (row: ListingAccount | null) : any => {
        // Note: Some `expiringAt` were in milliseconds and nanoseconds.
        //console.log(row);
        if (row && row.expiringAt && row.createdAt) {
            const n = Math.floor(Math.log10(row.expiringAt / row.createdAt));
            const expiringInMins = row.expiringAt / 10 ** n;
            return new Date(expiringInMins * 1000).toLocaleDateString('en-ca').replaceAll('-', '.');
        }
        return 'No';
    };

    const displayDate = (dateUpdate : number) => {
        if(dateUpdate) {
            const date = new Date(dateUpdate * 1000);
            return date.toLocaleDateString('en-ca').replaceAll('-', '.');
        }
        return "";
    }

    const IsPublisher = (publisher: string) : boolean => {
        //const { publicKey } = useWallet();
    
        const userData = JSON.parse(localStorage.getItem("userData")  as string);
        if(userData) {
            
            if(userData.wallet === publisher) {
                return true;
            }
        }
    
       
        return false;
    }

    const showToastError = (message) => {
        toast.error((<Fragment>{message}<div className="toast-close-section"><button className="link-button toast-close">close message</button></div></Fragment>),{
            autoClose: false,
            closeButton: false
          });
    }
    const deleteListing = (e) => {
        e.preventDefault();
        handleShow();
    }
    const deleteListingNow = () => {

        const userData = JSON.parse(localStorage.getItem("userData")  as string);

        const params = {
            token: userData.token,
            secret: userData.secret,
            txnId: listingAccount.id
        }
 
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/postings/delete', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    setIsDeleted(true);
                    handleClose();
                    // toast.success("Posting deleted successfully", {
                    //     className : "success-toast"
                    // });
                    navigate("/account");
                }else{
                    showToastError(data.message);
                }
            });

    } 
    return (
        <Fragment>


            <div className="newPost">
                
                <a href="#" className="title">{title}</a>
                <p>by {usernameJsx} | {listingAccount && listingAccount.history.length > 0 ? "Updated" : "Created"}: {displayCreatedDate(listingAccount)} | Expires: {expiringAtCol(listingAccount)} 
                <span className="newLine">|</span><span className="tagsList"> Tags: {tags.length === 0  ? 'None' : tags.map( (tag, index) => <Link className="tag" key={index} to={"/tag/" + tag}>{tag}</Link>)}
                { listingAccount && IsPublisher(listingAccount.publisher) && <Fragment>&nbsp;|&nbsp;<Link className="edit" to={'/edit/' + listingAccount.id}>Edit</Link></Fragment> }
                { listingAccount && IsPublisher(listingAccount.publisher)  && <Fragment>&nbsp;-&nbsp;<a href="#" className="edit link" onClick={deleteListing}>Remove</a></Fragment> }
                </span></p>

                {assetJsx}		
            </div>

            <div className="postDetail">
                <p className="marginBottomZero"><span dangerouslySetInnerHTML={{ __html: contentVal }} /></p>
                {/* <p><b>Email: <a href="#" className="newEmail">newbikeforsale@gmail.com</a></b></p> */}
             
                <Link  className="button" to={"/message/" + username + "/" + params.txnId}>Respond</Link>
                <h6>History</h6>
                    
                <ul className="history">
                    {listingAccount && listingAccount.history.reverse().map( (historyData, index) => <li key={index}><span>- &nbsp; &nbsp; &nbsp; </span><a target="_blank" rel="noreferrer" href={hasSignature ? "https://explorer.solana.com/tx/"+signatureList[index]+"?cluster="+ cluster : "#"}>Updated {displayDate(historyData.updatedAt)}</a></li>)}
                    <li><span>- &nbsp; &nbsp; &nbsp; </span><a target="_blank" rel="noreferrer" href={hasSignature ? "https://explorer.solana.com/tx/"+signatureList[signatureList.length - 1]+"?cluster="+ cluster : "#"}>Created {listingAccount && displayDate(listingAccount.createdAt as number)}</a></li>
                    
                </ul>	
            </div>
                 
                
             <Modal className="modal-registration" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>CONFIRM</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <div className="form-group">
                         Are you sure you want to delete this posting? 
                     </div> 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deleteListingNow}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>

    );
}


export default ViewListing;
 