import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import { backend_api_url, cluster, getUserSessionData} from './constants';
import MessageItem from "./MessageItem";

const Messages = (props : any) => {

    props.setClassInfo('messagesReplyPg messagesPg');

    const [currentUsername, setCurrentUsername] = useState<string>("");
    const [hasUserInfo, setHasUserInfo] = useState(false);
    const [messageJsx, setMessageJsx] = useState<JSX.Element[] | null>(null)
    

    const fetchAllConversation = () => {

        const userData = getUserSessionData();

        const paramsList = {
            token : userData ? userData.token : '',
            secret : userData ? userData.secret : '',
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(paramsList)
        };
        fetch(backend_api_url + 'api/v1/messages/conversations', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    setMessageJsx( data.message.map( (messageData : any) => <MessageItem key={messageData.id} messageData={messageData} />) );
                }
            });

    }

    const checkUser = () => {

         const userData = getUserSessionData();
        if(userData) {
            setHasUserInfo(true);
            setCurrentUsername(userData.username);

            fetchAllConversation();
        }

    }

    useEffect(() => {
		checkUser();
	
	  }, []);

    return (
        <Fragment>
            <h2>Messages</h2>

            <table className="messageReply" style={ {"border": "0"}} width="100%">
           {messageJsx}
            </table>
           
        </Fragment>

    );
}

export default Messages;