import React, {Fragment, useState, useEffect} from "react";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {fetchAllListingAccs, ListingAccount} from "@enginefish/solist-sdk/build"
import ListingItem from "./ListingItem";

const ListingResults = (props: any) => {

	const { connection } = useConnection();

	const [listingAccounts, setListingAccounts] = useState<ListingAccount[]>([]);

	const fetchAllListings = async (): Promise<any> => {

		const fetchListingData = await fetchAllListingAccs(connection);

		setListingAccounts(fetchListingData);
		//return fetchListingData;
	};

	useEffect(() => {
		fetchAllListings()
	
	  }, []);

    return (
        <Fragment>
            <br />
			{/* <button onClick={fetchAllListings}>Get Listing</button> */}
            <h3>View Listings</h3>
            <table className="table table-condensed table-striped">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>app</th>
                        <th>publisher</th>
                        <th>content</th>
                        <th>createdAt</th>
                        <th>expiringAt</th>
						<th>assets</th>
                        <th>edit</th>
                    </tr>
                </thead>
                <tbody>
					{listingAccounts.map( (listingAccount) => <ListingItem key={listingAccount.id} listingAccount={listingAccount}/>)}
				</tbody>
            </table>
        </Fragment>
    );
}

export default ListingResults;