import React, {Fragment, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom'; 
import Moment from 'react-moment';
import { cluster, SOLIST_PROGRAM_ID} from "./constants";
import { useConnection } from '@solana/wallet-adapter-react';
import { LAMPORTS_PER_SOL} from "@solana/web3.js";

const LedgerItem = (props: any) => {

    const { connection } = useConnection();
    const [ledgerType, setLedgerType] =  useState<JSX.Element | null>(null)
    const [txnDate, setTxnDate] = useState('');
    const [fromWallet, setFromWallet] = useState<JSX.Element | null>(null)
    const [toWallet, setToWallet] = useState<JSX.Element | null>(null)

    const [amount, setAmount] = useState(0);
    const [balance, setBalance] = useState(0);


    /*
    const sendingTxn = async () => {
        const userData = JSON.parse(localStorage.getItem("userData")  as string);
        if(userData) {
            
            if(userData.wallet) {
 
                const transactionData = await connection.getTransaction("5URxZdFzDC3xUELzPPFduyQJz8HzNwDENhpM8ERNdK7TpU3LsuE8jJzDu8u5uqbEwoDgNE3F7gDc8y7TxmXXxm1T");
                console.log(transactionData);
                const accountKeys = transactionData.transaction.message.accountKeys;
                 for(var i in accountKeys) {
                    console.log(accountKeys[i].toBase58());

                }
                const fee = transactionData.meta.fee / LAMPORTS_PER_SOL;
                 if(accountKeys[0].toBase58() == userData.wallet && accountKeys[2].toBase58() == '11111111111111111111111111111111' ) {
                    const amountOfSol =  (transactionData.meta.preBalances[0] - transactionData.meta.postBalances[0]  )/ LAMPORTS_PER_SOL;
                    const totalSol = amountOfSol - fee; // if sending, fee is deducted in the receiver amountOfSol + fee;
                    console.log(amountOfSol + ' Sending used ' + totalSol + ' SOLs from ' + accountKeys[0].toBase58() );
                }
            }
        }
        return;
    }
    const postingTxn = async () => {
        const userData = JSON.parse(localStorage.getItem("userData")  as string);
        if(userData) {
            
            if(userData.wallet) {
 
                const transactionData = await connection.getTransaction("3HzX62iZNygpZbz51M2Zdx8irMQoKerVFjfAWqWigStJkzx5eCx2Be7NZBf411ChHHyDNSD5TmrFawDgp98sQvqX");
                console.log(transactionData);
                const accountKeys = transactionData.transaction.message.accountKeys;
                 for(var i in accountKeys) {
                    console.log(accountKeys[i].toBase58());

                }
                const fee = transactionData.meta.fee / LAMPORTS_PER_SOL;
                 if(accountKeys[0].toBase58() == userData.wallet && accountKeys[accountKeys.length - 1].toBase58() == SOLIST_PROGRAM_ID.toBase58() ) {
                    //const amountOfSol = transactionData.meta.postBalances[1] / LAMPORTS_PER_SOL;
                    const amountOfSol =  (transactionData.meta.preBalances[0] - transactionData.meta.postBalances[0]  )/ LAMPORTS_PER_SOL;

                    const totalSol = amountOfSol ;//+ fee;
                    console.log('Posting used ' + totalSol + ' SOLs from ' + accountKeys[0].toBase58() );
                }
            }
        }
        return;
    }

    const incomingTxn = async () => {
        const userData = JSON.parse(localStorage.getItem("userData")  as string);
        if(userData) {
            
            if(userData.wallet) {

                const transactionData = await connection.getTransaction("634AoLkSrdAvQRvaRr7FztnkeXqShfqN8s7VEeM5hmgn35K2anAePH9L2AHHKrtwnJQ33XoscS6cS9CCTXkv8x3G");
                //console.log(transactionData);
                const accountKeys = transactionData.transaction.message.accountKeys;
                //console.log(accountKeys);
                for(var i in accountKeys) {
                    console.log(accountKeys[i].toBase58());

                }
                if(accountKeys[1].toBase58() == userData.wallet) {
                    const amountOfSol = transactionData.meta.postBalances[1] / LAMPORTS_PER_SOL;

                    console.log('Received ' + amountOfSol + ' SOLs from ' + accountKeys[0].toBase58() );
                }
            }
        }
        return;
    }
    */
    const fetchLedgerInfo = async () => {
        const signature = props.signature;
        
        const userData = JSON.parse(localStorage.getItem("userData")  as string);
        if(userData) {
            
            if(userData.wallet) {
 
                const transactionData = await connection.getTransaction(signature);
                const accountKeys = transactionData.transaction.message.accountKeys;
              
                const fee = transactionData.meta.fee / LAMPORTS_PER_SOL;
                let userWallet = userData.wallet;
                
 
                 if(accountKeys.length >=3 && accountKeys[0].toBase58() == userWallet && accountKeys[2].toBase58() == '11111111111111111111111111111111' ) {
                    const amountOfSol =  (transactionData.meta.preBalances[0] - transactionData.meta.postBalances[0]  )/ LAMPORTS_PER_SOL;
                    const totalSol = amountOfSol - fee; // if sending, fee is deducted in the receiver amountOfSol + fee;
                    //console.log(amountOfSol + ' Sending used ' + totalSol + ' SOLs from ' + accountKeys[0].toBase58() );
                     setLedgerType(<a target="_blank" rel="noreferrer" href={'https://explorer.solana.com/tx/'+signature+'?cluster='+ cluster}>Send</a>);

                    setToWallet(<a target="_blank" rel="noreferrer" href={'https://explorer.solana.com/address/'+accountKeys[1].toBase58()+'?cluster='+ cluster}>{accountKeys[1].toBase58()}</a>);
                    setFromWallet(<a target="_blank" rel="noreferrer" href={'https://explorer.solana.com/address/'+accountKeys[0].toBase58()+'?cluster='+ cluster}>{accountKeys[0].toBase58()}</a>);
                
                    setAmount(totalSol);
                    setBalance( (transactionData.meta.postBalances[0] ) / LAMPORTS_PER_SOL );

                }else if(accountKeys[0].toBase58() == userWallet && accountKeys[accountKeys.length - 1].toBase58() == SOLIST_PROGRAM_ID.toBase58() ) {
                   //const amountOfSol = transactionData.meta.postBalances[1] / LAMPORTS_PER_SOL;
                   const amountOfSol =  (transactionData.meta.preBalances[0] - transactionData.meta.postBalances[0]  )/ LAMPORTS_PER_SOL;

                   const totalSol = amountOfSol ;//+ fee;
                   //console.log('Posting used ' + totalSol + ' SOLs from ' + accountKeys[0].toBase58() );
 
                   setLedgerType(<a target="_blank" rel="noreferrer" href={'https://explorer.solana.com/tx/'+signature+'?cluster='+ cluster}>Post</a>);

                   setToWallet(<a target="_blank" rel="noreferrer" href={'https://explorer.solana.com/address/'+accountKeys[accountKeys.length - 1].toBase58()+'?cluster='+ cluster}>{accountKeys[accountKeys.length - 1].toBase58()}</a>);
                   setFromWallet(<a target="_blank" rel="noreferrer" href={'https://explorer.solana.com/address/'+accountKeys[0].toBase58()+'?cluster='+ cluster}>{accountKeys[0].toBase58()}</a>);

                   setAmount(totalSol);
                    setBalance( (transactionData.meta.postBalances[0] ) / LAMPORTS_PER_SOL );

                }else if(accountKeys[1].toBase58() == userWallet) {
                    //const amountOfSol = transactionData.meta.postBalances[1] / LAMPORTS_PER_SOL;
                    const amountOfSol =  (transactionData.meta.postBalances[1] - transactionData.meta.preBalances[1]  )/ LAMPORTS_PER_SOL;

                     setLedgerType(<a target="_blank" rel="noreferrer" href={'https://explorer.solana.com/tx/'+signature+'?cluster='+ cluster}>Receive</a>);
                    //    console.log(transactionData);
                    //console.log('Received ' + amountOfSol + ' SOLs from ' + accountKeys[0].toBase58() );
                    setToWallet(<a  target="_blank" rel="noreferrer" href={'https://explorer.solana.com/address/'+accountKeys[1].toBase58()+'?cluster='+ cluster}>{accountKeys[1].toBase58()}</a>);
                    setFromWallet(<a target="_blank" rel="noreferrer" href={'https://explorer.solana.com/address/'+accountKeys[0].toBase58()+'?cluster='+ cluster}>{accountKeys[0].toBase58()}</a>);
                    setAmount(amountOfSol);
                    setBalance( (transactionData.meta.postBalances[1] ) / LAMPORTS_PER_SOL );
                }
                

                const date = new Date(transactionData.blockTime * 1000);
                setTxnDate(date.toLocaleDateString('en-ca').replaceAll('-', '.'));

            }
        }

    }

    useEffect(() => {
        fetchLedgerInfo();
        //{props.signature}
    }, []);
    return (
        <Fragment>
            <tr>
                <td>{txnDate} </td>
                <td className="ellipsis"><span>{ledgerType}</span> </td>
                <td className="ellipsis"><span>{fromWallet}</span></td>
                <td className="ellipsis"><span>{toWallet}</span></td>
                <td>{amount}</td>
                <td>{balance}</td>
            </tr>
        </Fragment>

    )

}

export default LedgerItem;