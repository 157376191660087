import React, {Fragment, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';

 
 
const MessageListingItem = (props: any) => {

    const item = props.listing;
     
    let side = 'left';

    if(item.is_sender) {
         side = 'right';
    } 

    return (
        <Fragment>

<table className="messageReply" style={ {"border" : "0"} } width="100%"> 
            {

side === 'right' ? 
 
<tr style={ {"verticalAlign" : "top"} }>
<td align="right">
    <Moment local format="YYYY.MM.DD hh:mm a" >
    {item.posted_converted_date}
    </Moment>
    <p className="main"><b>Re: </b><Link to={"/post/" + item.txn_id }>{item.header}</Link></p>

    <p><div dangerouslySetInnerHTML={{ __html: item.content }}></div></p>
</td>	
<td style={ {"width" : "0px"} } align="right" className="msg-img-avatar"></td>
</tr>	

:


<tr style={ {"verticalAlign" : "top"} }>
<td width="90" align="left" className="msg-img-avatar"><Link to={"/user/" + item.username_display}><img className="user-avatar" alt="" src={item.avatar_display ? item.avatar_display : '/avatar.png'}/></Link></td>
<td align="left">
    <p>
    <Moment local format="YYYY.MM.DD hh:mm a" >
    {item.posted_converted_date}
    </Moment>
    <p className="main"><b>Re: </b><Link to={"/post/" + item.txn_id }>{item.header}</Link></p>
    <br/>
    <i><div dangerouslySetInnerHTML={{ __html: item.content }}></div></i>	
    </p>
</td>	
</tr>	



            }    	
            </table>
             
        </Fragment>

    )

}

export default MessageListingItem;