import React, {Fragment} from "react";
import { Link } from "react-router-dom";
import TagLists from "./TagLists";
import LatestListings from "./LatestListings";

const MainContainer = (props: any) => {
 
    const goToAddLink = (e) => {
        e.preventDefault();
        checkLogin();
    }

    const checkLogin = () => {
        window.location.href = "/add";
    }

    props.setClassInfo("homePg");

    return (
        <Fragment>

            <h5 className="top">Easily Create Posts on the Solana Blockchain</h5>

            <p>List Items for Sale, Jobs, Rentals or Log Your Photos & Thoughts. Decentralized, Immutable and Censorship-Resistant.</p>

            <a href="#" onClick={goToAddLink} className="add-post-link button">+ Add Post</a>

            <TagLists showExplore={true}></TagLists>
            <LatestListings provider={props.provider}  ></LatestListings>
             
        </Fragment>
    );
}

export default MainContainer;