import React, {Fragment, useEffect, useState} from "react";
import { ToastContainer, toast } from 'react-toastify';
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { Web3Auth  } from "@web3auth/modal";
import {createAccount, getBalance, cluster, getConnectionConfig, getUserSessionData, backend_api_url} from "./../constants";
import Badge from 'react-bootstrap/Badge';
import 'react-toastify/dist/ReactToastify.css';

const RightSidebar = (props: any) => {

    const [avatar, setAvatar] = useState<string>("/avatar.png");

    

    const checkLogin = () => {
        const userData = getUserSessionData();
        if(userData) {
            // window.history.replaceState(null, "Add Post", "/add");
            window.location.href = "/add";
        }else{
            loginHandler();
        }
    }

    const loadUserData = () => {
        const userData = getUserSessionData();
        if(userData) {
            props.setHasUserInfo(true);
                     
            props.loadUnreadHandler();
            props.loadSolBalance();
            fetchUserData();
        }
        //console.log("Load User Data");
    }

    
    const fetchUserData = async (): Promise<any> => {
        
        const userData = getUserSessionData();

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/postings', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                      
                    if(data.userInfo.avatar) {
                        setAvatar(data.userInfo.avatar);
                        props.setAvatar(data.userInfo.avatar);
                    }
                }
            });
	};


    const initWeb3Auth = async () => {

        const userData = JSON.parse(localStorage.getItem("userData")  as string);
        if(userData) {
            props.setHasUserInfo(true);

            //loadUserData();
        }

        try {
            const vars = getConnectionConfig();
            const web3authData = new Web3Auth({
                clientId: vars.clientId as string,
                chainConfig: {
                    chainNamespace: CHAIN_NAMESPACES.SOLANA,
                    chainId: vars.clientId,
                    rpcTarget: vars.rpcTarget, // This is the public RPC we have added, please pass on your own endpoint while creating an app
                }
            });
    
            props.setWeb3auth(web3authData);
            await web3authData.initModal();
                /*
            await web3auth.initModal({ 
                modalConfig: {
                  [WALLET_ADAPTERS.OPENLOGIN]: {
                    loginMethods: {
                      // change based on what you want to show
                      google: {
                        showOnModal: false,
                      },
                      facebook: {
                        showOnModal: false,
                      },
                      twitter: {
                        showOnModal: false,
                      },
                    },
                    // setting it to false will hide all social login methods from modal.
                    showOnModal: true,
                  },
                },
              } );
                */
            //alert('here 1');
            //console.log(web3authData.provider);
            if (web3authData.provider) {
                props.setProvider(web3authData.provider);
                loadUserData();
            }else{
                const web3Container = document.getElementById('w3a-container');
                web3Container.style.display = "block"; 
            }
            
        } catch (error) {
          console.error(error);
        }
        
    };

    useEffect(() => {
        loadUserData();
        initWeb3Auth();

        if(window.location.pathname === '/login') {
            checkLogin();
        }
	  }, []);
    
    const loginHandler = () => {
         props.loginWeb3Auth();
    } 
    
    

    const logoutHandler = async () => {
        localStorage.removeItem('userData');
        props.setHasUserInfo(false);
        await props.web3auth.logout();
        //setProvider(null);
    }
    const homeHandler = () => {
        window.history.replaceState(null, "New Page Title", "/");
    }

    return (
        <Fragment>
        

        <div id="RightPanel">
            <a className="logo" href="/" onClick={homeHandler}><img src="/images/logo.png" alt="Goodberry" title="Goodberry" width="150"/></a>

            <ul>

            
            <li><a href="/"><img src="/images/icons/home.png" /><span>Home</span></a></li>
            <li><a href="/search"><img src="/images/icons/search.png" /><span>Search</span></a></li>
            <li><a href="/tag/cloud"><img src="/images/icons/tags.png" /><span>Explore Tags</span></a></li>
            <li style={ {display: props.hasUserInfo ? 'none' : 'block' }}><a href="#" onClick={loginHandler}><img alt="" src="/images/icons/sign-up.png" /><span>Signup</span></a></li>
		    <li style={ {display: props.hasUserInfo ? 'none' : 'block' }}><a href="#" onClick={loginHandler}><img alt="" src="/images/icons/logout.png" /><span>Login</span></a></li>

            <li style={ {display: props.hasUserInfo ? 'block' : 'none' }}><a href="/messages"><img alt="" src="/images/icons/messages.png" /><span>Messages</span> <Badge style={ {"display" : props.unreadCount ? "inline-block" : "none" } } pill bg="danger">{props.unreadCount}</Badge></a></li>
            <li><a href="/add"><img alt="" src="/images/icons/add-post.png" /><span>Add Post</span></a></li>
            <li style={ {display: props.hasUserInfo ? 'block' : 'none' }}><a href="/wallet"><img alt="" src="/images/icons/wallet.png" /><span>Wallet : {props.solBalance.toFixed(4)}</span></a></li>
            <li style={ {display: props.hasUserInfo ? 'block' : 'none' }}><a href="/account"><img alt="" src={avatar} style={ {"borderRadius" : "50%"} } /><span>Account</span></a></li>
            <li style={ {display: props.hasUserInfo ? 'block' : 'none' }}><a href="#" onClick={logoutHandler}><img alt="" src="/images/icons/logout.png" /><span>Logout</span></a></li>
            </ul>	
        </div>
                
       
     


        {/* <ToastContainer position="top-center" /> */}

        </Fragment>
    );
}
export default RightSidebar