import React, {Fragment, useState, useEffect, useRef} from "react";
import ListingForm from "./ListingForm";
import ListingResults from "./ListingResults";
import MainContainer from "./MainContainer"; 
import TagView from "./TagView"; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {
  BrowserRouter ,
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";

import ViewListing from "./ViewListing";
import UserView from "./UserView";
import Messages from "./Messages";
import MyAccount from "./MyAccount";
import EditAccount from "./EditAccount";
import TagCloud from "./TagCloud";
import MessageUser from "./MessageUser"
 import { backend_api_url, getConnectionConfig, getUserSessionData } from "./constants";
import { PublicKey, LAMPORTS_PER_SOL, RpcResponseAndContext, SignatureResult} from "@solana/web3.js";
import { useConnection } from '@solana/wallet-adapter-react';
import { getBalance} from "./constants";
import ActivateAccount from "./ActivateAccount";
import WalletInfo from "./WalletInfo";
import RecoverPassword from "./RecoverPassword";
import Test from "./Test";
import Sample from "./Sample";
 

import { ToastContainer, toast } from 'react-toastify';

import { Web3Auth  } from "@web3auth/modal";
import { CHAIN_NAMESPACES, SafeEventEmitterProvider, WALLET_ADAPTERS } from "@web3auth/base";
import RPC from "./SolanaRpc";
import RightSidebar from "./sidebar/RightSidebar";
import Search from "./Search";
import SearchView from "./SearchView";
import Airdrop from "./Airdrop";


const Solist = (props: any) => {

    const [unreadCount, setUnreadCount] = useState<number>(0);
    const [solBalance, setSolBalance] = useState<number>(0);
    const { connection } = useConnection();
    const [web3auth, setWeb3auth] = useState<Web3Auth | null>(null);
    const [provider, setProvider] = useState<SafeEventEmitterProvider | null>(null);
    const [hasUserInfo, setHasUserInfo] = useState(false);
    const [avatar, setAvatar] = useState<string>("/images/icons/account-mobile.png");

    
    const usernameInput = useRef<HTMLInputElement>(null);
    
    const [currentUsername, setCurrentUsername] = useState<string>("");

    const loadWalletBalance = async (pubKey : string) => {
        //console.log(pubKey);
        // console.log('loadSolBalance');
        const pubkey =  new PublicKey(pubKey);

        const balance = await getBalance(pubkey, connection);
        //console.log(balance);
        setSolBalance(balance);
    }
    const loadSolBalance = async () => {

        const userData = getUserSessionData();
        if(userData) {
            
            if(userData.wallet) {
                await loadWalletBalance(userData.wallet);
            }else{
                const params = {
                    token : userData ? userData.token : '',
                    secret : userData ? userData.secret : '',
                }
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify(params)
                };
                fetch(backend_api_url + 'api/v1/users/wallet', requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        if(data.status === 1) {
                            loadWalletBalance(data.primary_wallet);
                        }
                    });
            }
        }
    }   
    const fetchUnreadCount = () => {
        const userData = getUserSessionData();
        if(!userData) {
            return false;
        }

        const params = {
            token : userData ? userData.token : '',
            secret : userData ? userData.secret : '',
        }
        //console.log(params);


        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/messages/unread-count', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    setUnreadCount(data.unreadCount);
                }
                 
            });
    }  

    const loadMessageUnread = () => {
        fetchUnreadCount();
        //console.log('loadMessageUnread');
    }
    
    useEffect(() => {

        //console.log('fetch unread count');
        // showToastError("Hello 123");
        // handleShowCompleteAccount();

        const userData = getUserSessionData();  
        
        if(userData) {
            fetchUnreadCount();
            if(userData.isNew === 1) {
                setCurrentUsername(userData.username);
                handleShowCompleteAccount();
            }
        }

	  }, []);

      const [showCompleteAccount, setShowCompleteAccount] = useState(false);
      const handleCloseCompleteAccount = () => setShowCompleteAccount(false);
      const handleShowCompleteAccount = () => setShowCompleteAccount(true);

      const [showRecoverPassword, setShowRecoverPassword] = useState(false);
      const handleCloseRecoverPassword = () => setShowRecoverPassword(false);
      const handleShowRecoverPassword = () => setShowRecoverPassword(true);
 
      const [resetInfo, setResetInfo] = useState(null);
 
const showWeb3Container = () => {
    const web3Container = document.getElementById('w3a-container');
    web3Container.style.display = "block"; 
}

const hideWeb3Container = () => {
    const web3Container = document.getElementById('w3a-container');
    web3Container.style.display = "none"; 
}

const getAccounts = async () => {
    let curProvider = provider
    if (!curProvider) {
        const web3authProvider = await web3auth.connect();
        setProvider(web3authProvider);
        curProvider = web3authProvider;            
    }
    
    const rpc = new RPC(curProvider);
    const address = await rpc.getAccounts();
    return address;
};

const doAirdrop = (pubkey) => {
 
    const config = getConnectionConfig();
    // Initialize signMsgFn by drawing out the private key first 
   
   if(config.cluster === "devnet") {
        const AIRDROP_AMOUNT = 2 * LAMPORTS_PER_SOL; // 1 SOL 

        pubkey && connection.requestAirdrop(pubkey, AIRDROP_AMOUNT)
        .then((id: string) => { 
            //console.log(`Transaction ID ${id}`);
            //setTxid(id);
            connection.confirmTransaction(id)
            .then((confirmation: RpcResponseAndContext<SignatureResult>) => {
                
                // toast.success("Airdropped 2 SOLS to your wallet", {
                //     className : "success-toast"
                // });
            });
        })
        .catch(console.error);
    }
}

const loginWeb3Auth = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
    
      return;
    }

    showWeb3Container();

    const web3authProvider = await web3auth.connect();
    setProvider(web3authProvider);    

    if (web3auth.provider) {
        setProvider(web3auth.provider);
    }
    hideWeb3Container();
    loginAccountHandler();
};

const loginAccountHandler = async () => {
    const userInfo = await web3auth.getUserInfo();
     const params = {
        email: userInfo.email,
        name: userInfo.name,
        typeOfLogin: userInfo.typeOfLogin,
    } 
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params)
    };
    fetch(backend_api_url + 'api/v1/users/login-v2', requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.status === 0) {
                //setErrorMessageLogin(data.message);
                showToastError(data.message);
            }else if(data.status === 1) {
                //show complete account
                if(data.isNew === 1 ) {
                    //doAirdrop(new PublicKey(data.wallet)); 
                    handleShowCompleteAccount();

                }
                const userInfo = {
                    token: data.token,
                    secret: data.secret,
                    username: data.username,
                    wallet: data.wallet,
                    isNew: data.isNew
                }
                
                setHasUserInfo(true);
                
                setCurrentUsername(data.username);

                localStorage.setItem('userData', JSON.stringify(userInfo));
                if(data.wallet === 'reload') {
                    reloadWallet();
                }else{
                    loadSolBalance();
                }
            }
        });
}

let retryWallet = 0;
    const reloadWallet = async() => {
        const address = await getAccounts();
        console.log(address);
         
        if(!address) {
            retryWallet++;
            if(retryWallet < 10) {
                setTimeout(reloadWallet, 2000);
            }
            return;
        }
        const userData = getUserSessionData(); 

        const params = {
            token: userData.token,
            secret: userData.secret,
            wallet: address[0]
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/update-wallet', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    const userInfo = {
                        token: data.token,
                        secret: data.secret,
                        username: data.username,
                        wallet: data.wallet,
                        isNew: userData.isNew
                    }

                    doAirdrop(new PublicKey(data.wallet));

                    setHasUserInfo(true);
                    loadSolBalance();

                    localStorage.setItem('userData', JSON.stringify(userInfo));
                }
            });

    }

    const loginHandler = () => {
        loginWeb3Auth();
   } 
 
   const logoutHandler = async () => {
    localStorage.removeItem('userData');
    setHasUserInfo(false);
    await web3auth.logout();
    //setProvider(null);
}

const showToastError = (message) => {
    toast.dismiss();
    setTimeout(function(){
        toast.error((<Fragment>{message}<div className="toast-close-section"><button className="link-button toast-close">close message</button></div></Fragment>),{
            autoClose: false,
            //closeButton: false
          });
    }, 500);
    
} 

const updateAccount = () => {
    
    if(usernameInput.current?.value == '') {
        showToastError("Please enter a username");
        return;
    }

    const userData = getUserSessionData();


    const params = {
        token: userData.token,
        secret: userData.secret,
        username: usernameInput.current?.value,
    }


    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params)
    };
    fetch(backend_api_url + 'api/v1/users/update', requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.status === 0) {
                showToastError(data.message);
                //setShowErrorCompleteAccount(true);
            }else if(data.status === 1) {
                //show complete account
                //setShowErrorCompleteAccount(false);
                setShowCompleteAccount(false);
                
                const userInfo = {
                    token: data.token,
                    secret: data.secret,
                    username: data.username,
                    wallet: data.wallet,
                    isNew: 0
                }
                setCurrentUsername(data.username);
                setHasUserInfo(true);
                localStorage.setItem('userData', JSON.stringify(userInfo));
            }
        });
}

    return (
        <Fragment>
            
            
         
      <div id="MobileTopNav">
		<div className="leftSide">
		  <a className="logo" href="/"><img src="/images/logo.png" alt="Goodberry" title="Goodberry" width="120"/></a> 
		</div> 
		<div className="rightSide loggedIn">
		  <ul>
            <li style={ {display: hasUserInfo ? 'none' : 'inline-flex' }}><a className="walletLink" href="#" onClick={loginHandler}><img alt="" src="/images/icons/sign-up.png" /></a></li>
		    <li style={ {display: hasUserInfo ? 'none' : 'inline-flex' }}><a className="walletLink" href="#" onClick={loginHandler}><img alt="" src="/images/icons/logout.png" /></a></li>

		    <li style={ {display: hasUserInfo ? 'inline-flex' : 'none' }}><a className="walletLink" href="/wallet"><span className="walletInfo">{solBalance.toFixed(4)}</span><img alt="" src="/images/icons/wallet.png" /></a></li>
		    <li><a href="/add"><img alt="" src="/images/icons/add-post.png" /></a></li>
		    <li style={ {display: hasUserInfo ? 'inline-flex' : 'none' }}><a className="messageLink" href="/messages"><img alt="" src="/images/icons/messages.png" /></a><span style={ {"display" : unreadCount ? "inline-block" : "none" } } className="msgDot"></span></li>
		  </ul>
		</div>  
	  </div>	

	  <div id="MobileBottomNav">
		<ul>
		  <li><a href="/"><img alt="" src="/images/icons/home.png" /></a></li>
		  {/* <li><a href="listing.html"><img alt="" src="/images/icons/search.png" /></a></li> */}
		  <li><a href="/tag/cloud"><img alt="" src="/images/icons/tags.png" /></a></li>
		  <li style={ {display: hasUserInfo ? 'inline-flex' : 'none' }}><a href="/account"><img alt="" className="mobile-avatar" src={avatar} /></a></li>
		  <li style={ {display: hasUserInfo ? 'inline-flex' : 'none' }}><a href="#" onClick={logoutHandler}><img alt="" src="/images/icons/logout.png" /></a></li>
		</ul>
	  </div>

            <RightSidebar setAvatar={setAvatar} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} hasUserInfo={hasUserInfo} setHasUserInfo={setHasUserInfo} showWeb3Container={showWeb3Container} hideWeb3Container={hideWeb3Container} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} resetInfo={resetInfo} showRecoverPassword={showRecoverPassword} handleCloseRecoverPassword={handleCloseRecoverPassword} showCompleteAccount={showCompleteAccount} handleCloseCompleteAccount={handleCloseCompleteAccount} solBalance={solBalance} loadSolBalance={loadSolBalance} unreadCount={unreadCount} loadUnreadHandler={loadMessageUnread}></RightSidebar>

 
      
            <div className="contents">
                <BrowserRouter >
                    <Routes>
                        <Route path="/" element={<MainContainer provider={provider}  setClassInfo={props.setClassInfo} />} />
                        <Route path="/login" element={<MainContainer provider={provider}  setClassInfo={props.setClassInfo}/>} />
                        <Route path="/listings" element={<ListingResults setClassInfo={props.setClassInfo} />} />

                        <Route path="/airdrop" element={<Airdrop />} />

                        <Route path="/add" element={<ListingForm setClassInfo={props.setClassInfo} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} loadSolBalance={loadSolBalance} />} />
                        <Route path="/edit/:txnId" element={<ListingForm setClassInfo={props.setClassInfo} loginWeb3Auth={loginWeb3Auth} loginAccountHandler={loginAccountHandler} web3auth={web3auth} provider={provider} setWeb3auth={setWeb3auth} setProvider={setProvider} loadSolBalance={loadSolBalance} />} />
                        <Route path="/post/:txnId" element={<ViewListing setClassInfo={props.setClassInfo} />} />
                        <Route path="/tag/cloud" element={<TagCloud setClassInfo={props.setClassInfo} />} />

                        <Route path="/search" element={<Search setClassInfo={props.setClassInfo} />} />
                        <Route path="/search/:searchTitle" element={<SearchView setClassInfo={props.setClassInfo} />} />

 
                        <Route path="/tag/:tagName" element={<TagView setClassInfo={props.setClassInfo} />} />
                        <Route path="/user/update" element={<EditAccount setClassInfo={props.setClassInfo} />} />
                        <Route path="/user/:userName" element={<UserView setClassInfo={props.setClassInfo} />} />
                        <Route path="/message/:userName/:txnId" element={<MessageUser setClassInfo={props.setClassInfo} loadHandler={loadMessageUnread} />} />
                        <Route path="/message/:userName" element={<MessageUser setClassInfo={props.setClassInfo} loadHandler={loadMessageUnread} />} />

                        <Route path="/messages" element={<Messages setClassInfo={props.setClassInfo} />} />
                        <Route path="/account" element={<MyAccount setClassInfo={props.setClassInfo} />} /> 
                        <Route path="/wallet" element={<WalletInfo setClassInfo={props.setClassInfo} provider={provider} solBalance={solBalance} loadSolBalance={loadSolBalance}/>} />

                    </Routes>
                </BrowserRouter >
            </div>
	        <div id="LeftBorder"></div>

            <ToastContainer position="top-center" />

            <Modal className="modal-registration complete-username" show={showCompleteAccount} onHide={handleCloseCompleteAccount}  backdrop="static">
                <Modal.Header closeButton>
                <Modal.Title>Complete Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className='control-label'>Username</label>
                        <input type="text" className="form-control" defaultValue={currentUsername} ref={usernameInput}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <button className="btn btn-danger blue" onClick={updateAccount}>
                    Update
                </button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default Solist;