import React, {Fragment, useState, useEffect} from "react";
import AssetsCell from './AssetsCell';
import { ListingAccount} from "@enginefish/solist-sdk/build"
import { Link } from "react-router-dom";
import {backend_api_url} from "./constants";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify";

const expiringAtCol = (row: ListingAccount) : any => {
    // Note: Some `expiringAt` were in milliseconds and nanoseconds.
     if (row.expiringAt && row.createdAt) {
        const n = Math.floor(Math.log10(row.expiringAt / row.createdAt));
        const expiringInMins = row.expiringAt / 10 ** n;
        return new Date(expiringInMins * 1000).toLocaleDateString('en-ca').replaceAll('-','.');
    }
    return 'No';
};
 
const IsPublisher = (publisher: string) : boolean => {
 
    const userData = JSON.parse(localStorage.getItem("userData")  as string);
    if(userData) {
        
        if(userData.wallet === publisher) {
            return true;
        }
    }

   
    return false;
}


const displayCreatedDate = (row: ListingAccount) => {
    const unixTime = row.createdAt as number;
    let date = new Date(unixTime * 1000);

    
    if( row.history.length > 0 ) {
        date = new Date(row.updatedAt * 1000);
    } 
    
    return date.toLocaleDateString('en-ca').replaceAll('-','.');
}

const ListingItem = (item: any) => {

    const [usernameJsx, setUsernameJsx] = useState<JSX.Element | null>(null)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isDeleted, setIsDeleted] = useState(false);

    const fetchOwnerInfo = (row: ListingAccount) => {
        
        fetch(backend_api_url + 'api/v1/users/info?wallet='+row.publisher)
        .then(response => response.json())
        .then(data => {
            if(data.id > 0) {
                setUsernameJsx(<Link to={"/user/" + data.username}>{data.username}</Link>);
            }else{
                setUsernameJsx(<span>N/A</span>);

            }
        });

    }

    
    const row = item.listingAccount;
    
    const deleteListing = (e) => {
        e.preventDefault();
        handleShow();
    }
    const deleteListingNow = () => {

        const userData = JSON.parse(localStorage.getItem("userData")  as string);

        const params = {
            token: userData.token,
            secret: userData.secret,
            txnId: row.id
        }
 
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/postings/delete', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    setIsDeleted(true);
                    handleClose();
                    // toast.success("Posting deleted successfully", {
                    //     className : "success-toast"
                    // });
                    
                }else{
                    toast.error(data.message);
                }
            });

    }
    useEffect(() => {
        fetchOwnerInfo(row);	
	  }, []);
 
    const isPublisher = IsPublisher(row.publisher)  ;
    return (
        <Fragment>
              <Modal className="modal-registration" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>DELETE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <div className="form-group">
                         Are you sure you want to delete this posting? 
                     </div> 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deleteListingNow}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>




            
            <div className="newPost" style={ { "display" : isDeleted ? "none" : "" }} >
                 <Link className="title" to={ "/post/" + row.id}>{row.header}</Link>

                <p>by {usernameJsx} | {row && row.history.length > 0 ? "Updated" : "Created"}: {displayCreatedDate(row)} | Expires: {expiringAtCol(row)} <span className="newLine">|</span><span className="tagsList"> Tags: {row.tags.length === 0  ? 'None' : row.tags.map( (tag, index) => <Link className="tag" key={index} to={"/tag/" + tag}>{tag}</Link>)}
                            { isPublisher && <Fragment>&nbsp;|&nbsp;<Link className="edit" to={'/edit/' + row.id}>Edit</Link></Fragment> }
                            { isPublisher && <Fragment>&nbsp;-&nbsp;<a href="#" className="edit link" onClick={deleteListing}>Remove</a></Fragment> }</span></p>

                <div id="HomeBanner assets">
                    <AssetsCell assets={row.assets} hideThumb={item?.hideThumb}></AssetsCell>
                </div>

            </div>
           

            {/* {item?.hideContent ? "" : <div className="content" dangerouslySetInnerHTML={{ __html: row.content }}></div>} */}

        </Fragment>
        


    )

}

export default ListingItem;