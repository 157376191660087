import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { backend_api_url } from "./constants";
import {fetchSingleListing, ListingAccount} from "@enginefish/solist-sdk/build"
import { PublicKey } from '@solana/web3.js';
import { useConnection } from '@solana/wallet-adapter-react';
import ListingItem from "./ListingItem";


const TagView = (props : any) => {

    props.setClassInfo('tagsCoolStuffPg');

    let params = useParams();
    const { connection } = useConnection();

    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)

    

    const fetchTagListings = async (): Promise<any> => {
        

        const paramsData = {
            tag: params.tagName,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(paramsData)
        };
        fetch(backend_api_url + 'api/v1/tags/postings', requestOptions)
            .then(response => response.json())
            .then(data => {
                    fetchAllListings(data.postings)
            });
	};

    const fetchAllListings = async (txn_ids: string[]): Promise<any> => {


        const promises = txn_ids.map(async txn_id => {
            const pubkey =  new PublicKey(txn_id);
            //console.log(txn_id);
           
            const listingAccount = await fetchSingleListing(connection, pubkey);
            return listingAccount;
        });

        const allListings = await Promise.all(promises)
        setListingJsx( allListings.map( (listingAccount) => <ListingItem key={listingAccount.id} hideContent={true} hideThumb={true}  listingAccount={listingAccount}/>) );
	};


    useEffect(() => {
		
        fetchTagListings();
	
	  }, [params.tagName]);

    return (
        <Fragment>
            <h6>Newest Posts for: {params.tagName}</h6>
            {listingJsx}
        </Fragment>

    );
}

export default TagView;