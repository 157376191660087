import React, {Fragment, useEffect, useState} from "react";
import TagLink from "./TagLink";
import { backend_api_url } from "./constants";

const TagLists = (props : any) => {
 
    //props.setClassInfo('tagsPg');

    const [tags, setTags] = useState<any[]>([]);

    const fetchRandomTags = () =>  {
        setTags([]);

        fetch(backend_api_url + 'api/v1/tags/list')
        .then(res => res.json())
        .then(
          (result) => {
            //console.log(result.tags);
            setTags( (existing)=> [...result.tags] );
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            
          }
        )
    }

    useEffect(() => {
		fetchRandomTags()
	
	  }, []);

    
    return (
        <Fragment>
            <div className="tags">
                {tags.map( (tag) => <TagLink key={tag.id} size={tag.size} name={tag.name}/>)}

                <a style={ {display: props.showExplore ? 'block' : 'none' }} href="/tag/cloud" className="explore">Explore Tags</a>

            </div>
        </Fragment>
    );
}

export default TagLists;