//import { writable, type Writable } from 'svelte/store';
import Arweave from 'arweave';
import type { CreateTransactionInterface } from 'arweave/node/common';
import type Transaction from 'arweave/node/lib/transaction';
import type { JWKInterface } from 'arweave/node/lib/wallet';
import axios from "axios";

export class ArweaveStorage {
	arweave: Arweave;
	jwk: JWKInterface;
	constructor() {
		this.arweave = Arweave.init({
			host: 'arweave.net',
			port: 443,
			protocol: 'https'
		});
		this.jwk = {
			kty: 'RSA',
			n: 'uCL28Ly2VI1sLcZAj_cpzQ8gVqZSBNOoTePztPg4aCNIcAGdxEnXDC0h5U8DnYF-Rw2E7v9hEUQWS5rUBmVMfSkU5vV2m7zUO9cS29tzrB4vJhTLIIl03ReyM1SX9J8yU3_XfAyn0mqV-h1GRt65q5wQLZRAqOX-X3O8K3dUmIIN_PE9vWtEBCk9KnksfO1RAgZddjwaQgDuZ_kziD3tD0YgWOxpS7H4kzb2zXVK1f2nB1xpThFkjWkyIu9RPwN1vK0lHgA-Z7AWOK1BiGKDV4xmHtu8eh05Xp4oOyryI4yOXDF6dKICfOGhIdEBy8g9Ued8G2Xcy47Qhl13Gues44swKMvFjR_W85H7L5YLc02Lc6dRDz6nt6H84wPqNK9eFvewMYkU3FgLTPUwWWux30XMLIGdEK1jOoOOmS1v9MdPmFgC3ZZmAzXFsil0TEXNqci9g7fJf7R_l-f70sDGA7IEusIMTiWvT_AL5cdXHyfV-UvqDjTPzDwFGFQs2i2KFvnLxDQEBp4zHhqChzjNsnxGGC2d5hUEEjxEjGxv2Oope1p0iMozLAIZJ03iP9IPjOH8FyI-nrto_wI0OiEFfdbASPyWw_g45d_XjnvgONY2ecWIpf66dZfrRqXekwr1CKf0GO8e0-6i79414PRWPOohmvNfjCjx8rwS_ZBK_RE',
			e: 'AQAB',
			d: 'PKQPLSMI7PmSKdUDiCX-1zBnFwI5Oqu13sj2hp_Ee8862OA_Y9hpgPIQGqOV7jYqLx4trEPgZL5GlWmM7_pGYWW5I4KtKAbc4kQnSs6P3pwmLOVmK_X4zaMzJaJzVLVk7ojIn2rPjgVaFP-1GDvba7--uDP9w2HTDv5fdvUaoAscrjOIgKwlvzURlr5HP3EsX31XJEwQ3bHO0eEI7pJuUrxmhMJ-vtYCpgDQ8MLy_VxGuK7fYz1pHwUI5OZpnFX4XUWsVp__7UfZWZr9XPwMgzWSoBD_ljuvnBB78VhoW_q7G3OT_3snVJc96JQbglAUmbehcKhwOX10APwg8HRhxBL_Ec_WMGkDuVh-RKQ4dxwQ8IL9v1zvYosrti1xodlh74pKfDeBzrAsxBWovPBBIG2-iEqLZ6seJyhThyoLFvLRS1FGDBjjRfiJuYy2zjXlmhbV7TcuIhbZiIHsVDfRzQukYWT8aBTb6u8py5C75z7eHA7zirLg5_gZgwOyPEmWiraii3iM07lQuzjCtu4qNkjmSzzdCSBIuMQPOR2sRArIIqZLUU7lkLdN_LQooeop-z_0eGbwqTntVRkcfPwHzdZIwQ3kEBfDeNn1anvwphGaU68rzynRq0aWTj5FBHdgMpZ0DJq6oGoFu23ZxEmOuQrTXlqNrPnATWQN730MZQ0',
			p: '4kbfMER-lOSmmw8e2cgltaM3SykEj9YsLVAx8vDQfR9N2bKGsiUHWdOaumnq0LitHdRq172zuE_rib6w_YaoGUD10N8EnLBJT9J-bEpJaNCDxeML3q_gYlkM17qXykbIx-Zo01OhBE9JjxQ4R3_pK3faQ5amGBN4hIwR1Sw57qwRolOdKi6JlI5tdWm9aWIsosxgEIwyqwAJ5w9dDvuUy88vg88Bn5O0pZfwHn7meIDxzEDOGm_ZpgtGkB-Z8cJsU1iPY2By37XUTVDSKHzdcvF7hhwuk_ISFyeNSvLDEtJnhCfxH2oVKgt6Amb32jU7DEugPS90y0oWM4GGCVjJMw',
			q: '0FMFOpMSLoXEOegmSxIBC_31aD1pULDuw0yiuWrK8AzvGA9bs6xZ9QgYHLWI3bo3fsS3pt0GIJKQXGHgdXBHqeosByRCICjRIvSo9TQ5fM8ISCVxLkhydkvLgwOD0AHY3VzT_G9uwtdpA_SMsmlFEGiqrG4ZN_AjdBVp7Qwtz5l4s5SMAvZ4nJwhuT_vFFsEw0zPpO-QgLtSqFrUkaOMVDNNq_6A467CigH-DEx3yb3L6wvmypFQqK_xIwfZn0gTTaSs9WtubjbXY1zxhNkCC9wW46700WhiE5xrB2dHUuCZQZvkQ7eyaal76avxMANAmAckT0222Kcl6hZIM4EIqw',
			dp: 'PC4JMfcli0f8n4S3-cfnb5WHITA5jF-zxCFuB2TQrlZjCCEY_cwnVlaW8jk1QGm9cQ-LkvFbGQv4YtwainMDxiOmvPRzb5DbDqW3BeoD-CJsyXaHM6SnEsEIT1_K-vs0byXyfzHgwxqrP6hrB2kXhH4NyKgKaKDVDruKCp4GklmlHv0J8awK3z26Et4aiCqaIHommoAPAbey6ocFgvSaMFJcGrw1fFF2XAw6yoGJnkb9CAu8RP-2bRjWnYIqGUhSMyB8kjuWkbT0T9PM8QO0idt7tMFOCf75nLriMyq5gwn-igFteaNnGZWPz4-_H4PEvpJmfZ9feqmrmes60MWSTQ',
			dq: 'zoP-gvqY45Wn2N_SVkka0JCJXzTB2kQL7X9jMPw1AZNQ0kEnXw5UfxvvC0bKz0NzEDBi_v_Muy2P_oToxoLwB_kkfE_tXYQZDtYLSEjlVFzIkPzNHlyjW7H3pYEugl3SZl9Hps-wtWZlrI4ZjCPfW0znJNhgF5vvCXzQ7TVNlKdHiH6R7b34AwikRcijcUJpjlHu5kq2rWcFoe-pGldPHYleTkqV6x-rqrQe7IJBnbSRGglKWTWFCDSij1-Q1N3WhPnim8hMSOYEz3Or2U5nQOqFpmAiQhWAZICDeWtgwT_1rZykxJyuOSDSveq9xiLzPcKWtCcOQ-gvJcfA-43G0Q',
			qi: 'l7FzsMoUGcKPcGwAC35PmK8SBJAUUJP0wNfTH6tVfRdaZCiGs_HX9cgH5_NGOrXw9M6dvu0x-M0gUcbZkDRLdCzugaQKaUx5kCryY3Upi6dIktv5OeybxF0ro9yRlZBuhedB7dbQ73ZEmzAOC1eYJzaS8Qi9x2lRFpPXtDIYvL-eBVIcFEx89PyfXbbvVvdNcazoB763oM-bAchw-0SHSP7ZpoBA5_O3_6y_3OWeXmAosBKcEe4mZ5QJhRg80HIqQxuqZhPdxYzanl7KIgP0v4oYQ63yTBLDYaaIy-StkgFC7VGHAr-BrexLvGcfqO8brQjEu648WQbgECIkVK8U2A'
		};
	}

	async createTransaction(params: Partial<CreateTransactionInterface>): Promise<Transaction> {
		return await this.arweave.createTransaction(params, this.jwk);
	}

	async getBalance(): Promise<string> {
		const address = await this.arweave.wallets.getAddress(this.jwk);
		const winstons = await this.arweave.wallets.getBalance(address);
		const ar = this.arweave.ar.winstonToAr(winstons);
		return ar;
	}

	async uploadFile(params: Partial<CreateTransactionInterface>): Promise<Transaction> {
		const tx = await this.arweave.createTransaction(params, this.jwk);
		await this.arweave.transactions.sign(tx, this.jwk);
		const uploader = await this.arweave.transactions.getUploader(tx);
		while (!uploader.isComplete) {
			await uploader.uploadChunk();
		}
		return tx;
	}

	async retrieveFile(txId: string): Promise<string> {
		const res = await axios.get(`https://arweave.net/${txId}`);
		return res.data;
	}
}
/*
const createArweaveStore = (): Writable<ArweaveStorage> => {
	const storage = new ArweaveStorage();
	return writable(storage);
};

export const arweaveStore = createArweaveStore();

export const listingAccountStore: Writable<ListingAccount[]> = writable([]);
*/