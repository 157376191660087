import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import TagLists from "./TagLists";
import { backend_api_url } from "./constants";
import TagSearch from "./TagSearch";

const TagCloud = (props : any) => {

    props.setClassInfo('tagsPg');

    const [popularJsx, setPopularJsx] = useState<JSX.Element[] | null>(null)
    const [hotJsx, setHotJsx] = useState<JSX.Element[] | null>(null)
    const [newJsx, setNewJsx] = useState<JSX.Element[] | null>(null)
    
    

    const fetchTags = async (): Promise<any> => {
        

        const paramsData = {
            
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(paramsData)
        };
        fetch(backend_api_url + 'api/v1/tags/clouds', requestOptions)
            .then(response => response.json())
            .then(data => {
                    //console.log(data);

                    setPopularJsx( data.popular.map( (tag : any) => <Link className="tag-list-name" key={tag.id} to={"/tag/"+tag.name}>{tag.name}</Link>) );
                    setHotJsx( data.hot.map( (tag  : any ) => <Link className="tag-list-name" key={tag.id} to={"/tag/"+tag.name}>{tag.name}</Link>) );
                    setNewJsx( data.new.map( (tag  : any) => <Link className="tag-list-name" key={tag.id} to={"/tag/"+tag.name}>{tag.name}</Link>) );

            });
	};
    useEffect(() => {
            fetchTags();
    }, []);



    return (
        <Fragment>
        <TagLists showExplore={false}></TagLists>
        <TagSearch/>
        <div>
            <div className="tagCategories">
            <h6>Popular</h6>	
            {popularJsx}
            </div>

            <div className="tagCategories">
            <h6>Hot</h6>	
            {hotJsx}
            </div>

            <div className="tagCategories">
            <h6>New</h6>	
            {newJsx}
            </div>  
        </div> 
        
        </Fragment>
    );
}

export default TagCloud;