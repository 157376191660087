import { PublicKey , Keypair, LAMPORTS_PER_SOL, export_default} from "@solana/web3.js";
import type { Connection, Cluster } from '@solana/web3.js';
import bs58 from 'bs58';
import RPC from "./SolanaRpc"; 
import nacl from "tweetnacl";
import { toast } from "react-toastify";

export interface Application {
	name: string;
	publicKey: string;
}
 
export const backend_api_url : string  =  process.env.REACT_APP_BACKEND_SERVER_URL as string;

export const cluster: string = process.env.REACT_APP_CLUSTER  as string;

export const applications: Array<Application> = [
	{
		name: 'alpha-dapp',
		publicKey: '5qF8rud121fgmWL7JRp5RCznceE7MpwrBzKdQngVf42n'
	},
	{
		name: 'beta-dapp',
		publicKey: 'HRibGa2cXZA8RVBH4GP6CqNn9fpC8pSqVkztmS6vHy5a'
	},
	{
		name: 'charlie-dapp',
		publicKey: '6HErBjD5xtXRz1yorzb4xTshKFvYpiNmS2xpEqm69yLt'
	}
];


export const SOLIST_PROGRAM_ID = new PublicKey("ListaMe2rbrc8w5X2gnzQvZzPBGLNCB6VuVAKCgRHU4");

export const createAccount = () : Keypair => {
	const keypair = Keypair.generate();
	return keypair
};
 
export const getBalance = async (publicKey: PublicKey, connection: Connection) => {

	const lamports = await connection.getBalance(publicKey);
	return lamports / LAMPORTS_PER_SOL;
};

export const getDataSizeOfListing = (): number => {
	return (
		8 + // Account disscriminator
		1 + // version
		50 + // header
		1_000 + // Content (1 KB)
		32 + // Publisher
		32 + // Application
		8 + // createdAt
		1 + // expiringAt (Option)
		8 + // expiredAt
		1 + // active
		8 + // updatedAt
		200 + // assets (200 B)
		200 + // tags (200 B)
		1_000 // history (1 KB)
	);
};


export const getMinimumLamports = async (connection: Connection) : Promise<number> => {
	const listingSpace = getDataSizeOfListing();

	const lamports = await connection.getMinimumBalanceForRentExemption(
		listingSpace
	);
	return lamports;
}

export const getPublisher = async () => {
	const walletSecret = await getUserWalletSigner();
	 
	const publisher = Keypair.fromSecretKey(
		bs58.decode(walletSecret)
	  );

	return publisher;      
}


export const getPublisherNew = async (provider) => {
          
	const rpc = new RPC(provider);
	const privateKey = await rpc.getPrivateKey();
	//console.log(privateKey);
	let secretKeyUnit8Format = new Uint8Array(Buffer.from(privateKey, 'hex'));
	const publisher = Keypair.fromSecretKey(
		secretKeyUnit8Format
	  );
	
	return publisher;      
} 

export const getUserWalletSigner = async () => {
	const userData = JSON.parse(localStorage.getItem("userData")  as string);

	const params = {
		token: userData.token,
		secret: userData.secret,
	}

	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(params)
	};

	const response = await fetch(backend_api_url + 'api/v1/users/wallet-secret', requestOptions);
	const json = await response.json();

	return json.primary_wallet_secret;
   
}

export const getUserSessionData = () => {
	return JSON.parse(localStorage.getItem("userData")  as string);

}

export const getCurrentWalletPublicKey = ()  => {

	const userData = getUserSessionData();
	if(userData) {
		
		if(userData.wallet) {
			const pubWal = new PublicKey(userData.wallet);
			return pubWal;
		}
	}
	return null;
}

const selectClusterFromChainId = (chainId: string): Cluster => {
	switch (chainId) {
	  case "0x1": {
		return "mainnet-beta";
	  }
	  case "0x2": {
		return "testnet";
	  }
	  default: {
		return "devnet";
	  }
	}
  };

  export interface ConnectionConfig {
	clientId: string;
	chainId: string;
	rpcTarget: string;
	cluster: Cluster;
  }

export const getConnectionConfig = (): ConnectionConfig => {
	const chainId = process.env.REACT_APP_WEB_AUTH_CHAIN_NET || "";
	const cluster = selectClusterFromChainId(chainId);
	return {
	  clientId: process.env.REACT_APP_WEB_AUTH_CLIENT_ID || "",
	  chainId: process.env.REACT_APP_WEB_AUTH_CHAIN_NET || "",
	  rpcTarget: process.env.REACT_APP_WEB_AUTH_RPC_TARGET || "",
	  cluster,
	};
  };
  
  export const signMsgCurriedFn = async (provider): Promise<
  (message: Uint8Array) => Promise<Uint8Array>
> => {
  // Retrieve the PrivateKey first.
  if (!provider) {
	throw new Error("`web3auth` or `web3auth.provider` is not defined.");
  }
  const privateKeyStr = (await provider.request({
	method: "solanaPrivateKey",
  })) as string;
  const publisher = Keypair.fromSecretKey(Buffer.from(privateKeyStr, "hex"));

  // Return a closure with privateKey in scope
  return async (message: Uint8Array): Promise<Uint8Array> => {
	const signature = nacl.sign.detached(message, publisher.secretKey);
	return signature;
  };
};