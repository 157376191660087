import React, {Fragment, useState, useEffect} from "react"; 
import { Link } from "react-router-dom";
import { backend_api_url} from './constants';
import { ToastContainer, toast } from 'react-toastify';
import Moment from 'react-moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import 'react-toastify/dist/ReactToastify.css';
 
 
 
const ThreadItem = (props: any) => {
 
    const [isDeleted, setIsDeleted] =  useState<boolean>(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [overlayElem, setOverlayElem] = useState<JSX.Element | null>(null);

    
    const [isShowAction, setIsShowAction] =  useState<boolean>(false);

 
    const deleteMessage = (e) => {
        e.preventDefault();
        handleShow();
    }

    const deleteMessageNow = () => {
 
        const userData = JSON.parse(localStorage.getItem("userData")  as string);

        const paramsList = {
            token : userData ? userData.token : '',
            secret : userData ? userData.secret : '',
            id : props.messageData.id,
        }
 
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(paramsList)
        };
        fetch(backend_api_url + 'api/v1/messages/delete', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    handleClose();
                    setIsDeleted(true);
                    // toast.success("Message Deleted Successfully", {
                    //     className : "success-toast"
                    // });
                }
            });
    }

    const item = props.messageData;
 
    

    const editMessage = (e) => {
        e.preventDefault();
        props.editMessage(item.id);
    }

    useEffect(() => {
        if(item.is_deleted_by_from_user == 1) {
            setIsDeleted(true);
        }
	  }, []);
    let hideTimeout;
    const checkAction = () => {
        if(!isDeleted) {
            setIsShowAction(true);
            if(hideTimeout) {
                clearTimeout(hideTimeout);
            }
        }
    }
    const hideAction = () => {
        hideTimeout = setTimeout(hideNow, 3000);
    }

    const hideNow = () => {
        setIsShowAction(false);

    }

    const popover = (
        <Popover id="popover-basic">
          <Popover.Body style={{"fontSize" : "16px"}}>
            <span className="message-action" onClick={editMessage}><i className="fa fa-edit"/>&nbsp;&nbsp;&nbsp;Edit</span>
            <br />
            <span className="message-action" onClick={deleteMessage}><i className="fa fa-trash"/>&nbsp;&nbsp;&nbsp;Delete</span>
          </Popover.Body>
        </Popover>
      );
       
      
     let side = 'left';

    if(item.is_sender) {
         side = 'right';
    } 

    return (
        <Fragment>
             <table className="messageReply" style={ {"border" : "0"} } width="100%"> 
            {
side === 'right' ? 
<tr style={ {"verticalAlign" : "top"} }>
            <td align="right">
                {/* <a href="#" className="black">edit</a> - <a href="#" className="black">delete</a> | 2:03 pm */}

                <div className="msg-info-time">
                        { item.is_sender == 1 && !isDeleted    ?  <Fragment>  
                            <OverlayTrigger trigger={["hover", "click", "focus"]} delay={{ show: 150, hide: 3000 }}   placement="left" overlay={popover}>
                                <i className="message-action fa fa-ellipsis-v" aria-hidden="true"></i>
                            </OverlayTrigger> |</Fragment>  : "" } 
                     &nbsp;
                     <Moment local format="YYYY.MM.DD hh:mm a" >
                      {item.posted_converted_date}
                     </Moment>
                      {isDeleted ? '' : ( item.is_edited == 1 ? ' - edited' : '')}
                      </div>

                {/* <p className="main"><b>Re: </b><a href="#">Post Name</a></p> */}
                <p>
                <div className="" onMouseOver={checkAction} onMouseOut={hideAction}>
                        &nbsp;{isDeleted ? <span className="deleted-message">This message has been deleted.</span> : item.content}
                    </div> 
                </p>
            </td>	
            <td style={ {"width" : "0px"} } align="right"></td>
        </tr>
:



<tr style={ {"verticalAlign" : "top"} }>
                <td align="left" className="msg-img-avatar msg-left-avatar"><Link to={"/user/" + item.username_display}><img className="user-avatar" alt="" src={item.avatar_display ? item.avatar_display : '/avatar.png'}/></Link></td>
                <td align="left">
                    <p>
                    <div className="msg-info-time">
                        { item.is_sender == 1 && !isDeleted  ?  <Fragment>  
                            <OverlayTrigger  trigger={["hover", "click", "focus"]} delay={{ show: 150, hide: 3000 }}   placement="left" overlay={popover}>
                                <i className="message-action fa fa-ellipsis-v" aria-hidden="true"></i>
                            </OverlayTrigger> |</Fragment>  : "" } 
                     &nbsp;
                     <Moment local format="YYYY.MM.DD hh:mm a" >
                      {item.posted_converted_date}
                     </Moment>
                      {isDeleted ? '' : ( item.is_edited == 1 ? ' - edited' : '')}
                      </div> 

                    <div className="" onMouseOver={checkAction} onMouseOut={hideAction}>
                    &nbsp;{isDeleted ? <span className="deleted-message">This message has been deleted.</span> : item.content}
                    </div> 

                    </p>
                </td>	
                </tr>

            }    	
            </table>

            <Modal className="modal-registration"  show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>DELETE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <div className="form-group">
                         Are you sure you want to delete this message? 
                     </div> 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deleteMessageNow}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>

    )

}

export default ThreadItem;