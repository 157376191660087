import React, {Fragment, useEffect, useState} from "react";
import ListingItem from "./ListingItem";
import { useConnection } from '@solana/wallet-adapter-react';
import {fetchSingleListing, ListingAccount} from "@enginefish/solist-sdk/build"
import { backend_api_url } from "./constants";
import { PublicKey } from '@solana/web3.js';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserView = (props : any) => {

    props.setClassInfo('userPg');
    
    const { connection } = useConnection();

    const params = useParams();

    const [currentUserName, setCurrentUserName] = useState<string>("");
	const [userWallets, setUserWallets] = useState<string>("");
	const [avatar, setAvatar] = useState<string>("/avatar.png");
	const [about, setAbout] = useState<string>("");
    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)

    const fetchAllUserListings = async (): Promise<any> => {
        
        const username = params.userName as string;


        setCurrentUserName(username);

        const paramsInfo = {
            username: username,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(paramsInfo)
        };
        fetch(backend_api_url + 'api/v1/users/postings-by-user', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    fetchAllListings(data.postings)
                    let wal = "";
                    for(var i in data.userInfo.wallets) {
                        wal += data.userInfo.wallets[i] + "<br />";
                    }
                    setUserWallets(wal);

                    if(data.userInfo.avatar) {
                        setAvatar(data.userInfo.avatar);
                    }
                    if(data.userInfo.about) {
                        setAbout(data.userInfo.about);
                    }
                }else{
                    showToastError("Invalid User");
                }
            });
	};

    const showToastError = (message) => {
        toast.error((<Fragment>{message}<div className="toast-close-section"><button className="link-button toast-close">close message</button></div></Fragment>),{
            autoClose: false,
            closeButton: false
          });
    }
	const fetchAllListings = async (txn_ids: string[]): Promise<any> => {


        const promises = txn_ids.map(async txn_id => {
            const pubkey =  new PublicKey(txn_id);
            //console.log(txn_id);
           
            const listingAccount = await fetchSingleListing(connection, pubkey);
            return listingAccount;
        });

        const allListings = await Promise.all(promises)
        setListingJsx( allListings.map( (listingAccount) => <ListingItem key={listingAccount.id} hideThumb={true} listingAccount={listingAccount}/>) );

	};

	useEffect(() => {
		
        fetchAllUserListings();
	
	  }, []);

    return (
        <Fragment>

        <table className="user"  style={ { "border" : "0"} }  width="100%">
                
                <tr style={ { "verticalAlign" : "top"} } className="avatar" >
                <td width="140"><img alt="" src={avatar}/></td>
                <td>
                    <p><span><b>User</b></span>:&nbsp; {currentUserName}<br/><span><b>About</b></span>:</p>
                    <p><span dangerouslySetInnerHTML={{ __html: about }} /></p>	

 
                    <Link className="button" to={"/message/" + currentUserName} >Message</Link>
                </td>	
                </tr>	

            </table>

            <h6>Newest Posts</h6>
            
            {listingJsx}
            
            {/* <ToastContainer position="top-center" /> */}
        </Fragment>

    );
}

export default UserView;