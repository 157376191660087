import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import {cluster} from "./constants";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';

const WalletLink = (props : any) => {

    const [wallet, setWallet] = useState<string>("");
    const [walletJsx, setWalletJsx] = useState<JSX.Element | null>(null)
    const [walletExplorerJsx, setWalletExplorerJsx] = useState<JSX.Element | null>(null)
    const [copyJsx, setCopyJsx] = useState<JSX.Element | null>(null)

    const [value, setValue] = useState<string>("");
    const [copied, setCopied] = useState(false);

    const copyNow = () => {
        setCopied(true);
        // toast.success("Copied to clipboard", {
        //     className : "success-toast"
        // });
    }
    const getUserWallet = () => {
        const userData = JSON.parse(localStorage.getItem("userData")  as string);

        if(userData) {
            const wallet = userData.wallet;
            setWallet(wallet);
            //setCopyJsx();
            setWalletExplorerJsx( <Fragment> <a target="_blank" rel="noreferrer" href={'https://explorer.solana.com/address/'+wallet+'?cluster='+ cluster}> {wallet} </a> &nbsp; <CopyToClipboard text={wallet} onCopy={copyNow}><i className="fa fa-copy"></i></CopyToClipboard> &nbsp; 
            </Fragment> );
            setWalletJsx(  <Link to="/wallet"> {wallet} </Link> );

        } 

    }

    useEffect(() => {
         
        getUserWallet();

    }, []);

    return (
        <Fragment>

            {props?.isExternal ? walletExplorerJsx : walletJsx}

        </Fragment>

    );
}

export default WalletLink;