import React, {Fragment, useEffect} from "react";
import { Link } from "react-router-dom";



const TagLink = (props : any) => {
    //console.log(props);

    let className = 'small';

    if(props.size > 24) {
        className = 'large';
    }else if(props.size > 18) {
        className = 'medium';
    }

    return (

        <Link  className={className} to={'/tag/' + props.name}>{props.name}</Link>

    );
}

export default TagLink;