import React, {Fragment, useEffect, useState, useRef} from "react";
import ListingItem from "./ListingItem";
import { useConnection } from '@solana/wallet-adapter-react';
import {fetchSingleListing, ListingAccount} from "@enginefish/solist-sdk/build"
import { backend_api_url } from "./constants";
import { PublicKey } from '@solana/web3.js';
import { Link } from "react-router-dom";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import ReactQuill from 'react-quill';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';

//import {Editor, EditorState} from 'draft-js';
// import 'draft-js/dist/Draft.css';

import { EditorState, ContentState } from 'draft-js'
import Editor from '@draft-js-plugins/editor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';
import { convertToHTML } from 'draft-convert';
import linkifyHtml from 'linkify-html';
import WalletLink from "./WalletLink";

const EditAccount = (props: any) => {

    props.setClassInfo('userPg accountPg updateAccountPg');

    const { connection } = useConnection();
	const [avatar, setAvatar] = useState<string>("/avatar-bak.png");
	const [about, setAbout] = useState<string>("");
    const [username, setUsername] = useState<string>(""); 
    const [email, setEmail] = useState<string>("");
    const [file, setFile] = useState<string>("");
    const [countryJsx, setCountryJsx] = useState<JSX.Element[] | null>(null);
    const [showCountry, setShowCountry] = useState(false);

    const [currentWallet, setCurrentWallet] = useState<string>("");

    

    const [userCountryId, setUserCountryId] = useState<string>("");

    const contentInput = useRef<any>(null);
    const timezoneInput = useRef<any>(null);
    const countryInput = useRef<any>(null);

    const hiddenFileInput = useRef<any>(null);
    let navigate = useNavigate();

    const saveAccount = () => {
        const userData = JSON.parse(localStorage.getItem("userData")  as string);
        const options = { defaultProtocol: 'https' };

        const about = linkifyHtml(convertToHTML(editorState.getCurrentContent()), options);


        const params = {
            token: userData.token,
            secret: userData.secret,
            about: about, //contentInput.current.value,
            //timezone: timezoneInput.current.value,
            country_id : countryInput.current.value,
            avatar: avatar
        }
        //console.log(params);

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/save', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    //setUserWallets(wal);
                    //toast.success("Account Save Successfully");
                    navigate("/account");
                }
            });
    }
    const handleFileClick = () => {
        hiddenFileInput.current.click();
    }
    const handleChange = (event: any) => {
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            const imageString = event.target?.result as string;
            setFile(imageString); // *** Only change is here
            //console.log(imageString);
            setAvatar(imageString);

        };
    };

    const changeHandler = (event) => {
        //console.log(event.target.value);
        setUserCountryId(event.target.value);
    }
    const fetchUserInfo = async (): Promise<any> => {
        
        const userData = JSON.parse(localStorage.getItem("userData")  as string);

        setUsername(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/details', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    //setUserWallets(wal);

                    if(data.avatar) {
                        setAvatar(data.avatar);
                    }
                    if(data.about) {
                        setAbout(data.about);

                        const processedHTML = DraftPasteProcessor.processHTML(data.about);
                        const contentState = ContentState.createFromBlockArray(processedHTML);
                        setEditorState(EditorState.createWithContent(contentState));
                    }
                    if(data.email) {
                        setEmail(data.email);
                    }

                   
                    /*
                    if(data.timezone) {
                        timezoneInput.current.value = data.timezone
                    }
                    */
                    setCurrentWallet(data.wallet);
                    setCountryJsx(data.countries.map( (country) => <option key={country.id} value={country.id}>{country.name}</option> ));
                    setUserCountryId(data.country_id);
                    setShowCountry(true);
                }
            });
	};

	useEffect(() => {
		fetchUserInfo();
	  }, []);

    const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
    ],
    };

    const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
    ];
    
    

    const [editorState, setEditorState] = React.useState(
        // () => EditorState.createEmpty(),

        // () =>  EditorState.createWithContent(
        //     ContentState.createFromBlockArray(convertFromHTML('<p>My initial content.</p>'))
        //   ),


          //move focus to the end. 
          () => {

            const processedHTML = DraftPasteProcessor.processHTML(about);
            const contentState = ContentState.createFromBlockArray(processedHTML);
            return EditorState.createWithContent(contentState);
          }
      );
 
      const linkifyPlugin = createLinkifyPlugin();
     return (
        <Fragment>

            <h2>Update Account</h2>

            <table className="user" style={ {"border" : "0"} }  width="100%">

            <tr style={ {"verticalAlign" : "top"} } className="avatar">
                <td width="110">
                <img  alt="" src={avatar}/>
                <a href="#" onClick={handleFileClick}>Update</a>	
                <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{display: 'none'}}
                    />
                </td>
                <td></td>	
            </tr>	

            </table>

            <p><span><b>Wallet </b></span>:&nbsp; <WalletLink/></p>

            <p><span><b>Email </b></span>:&nbsp; {email}</p>

            <p><span><b>Username</b></span>:&nbsp; {username}</p>

 
            <label>About (optional)</label>

            <div className="br"></div>

            <div className='solist-wysywg'>
                    <Editor
                    
                        editorState={editorState}
                        onChange={setEditorState}
                        plugins={[linkifyPlugin]}
                         ref={contentInput}
                        />
                    </div> 

            <div className="br"></div>

            <label>Location</label>

            <div className="br"></div>

            {
                !showCountry ? "" : 
            
                <select onChange={changeHandler} value={userCountryId} className="form-control" ref={countryInput}>
                    <option value="">Please Choose</option>
                    {countryJsx}
                </select>
            }
 
            <button className="link-button account-button" onClick={saveAccount}>Save</button>
 
            <ToastContainer position="top-center" />
            
        </Fragment>

    );
}

export default EditAccount;