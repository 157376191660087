import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Solist from './components/Solist' 
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    GlowWalletAdapter,
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import React, {useMemo, useState} from 'react';


// import the styles
require('@solana/wallet-adapter-react-ui/styles.css');


function App() {

    const [classInfo, setClass] = useState('');

    const setClassInfo = (params) => {
        //console.log(params);
        setTimeout(function(){
            setClass(params)
        }, 1);
    }
    // you can use Mainnet, Devnet or Testnet here
    const solNetwork = WalletAdapterNetwork.Devnet;
    const endpoint = useMemo(() => clusterApiUrl(solNetwork), [solNetwork]);
    // initialise all the wallets you want to use

    const wallets1 = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new GlowWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter(),
            new TorusWalletAdapter(),
            new LedgerWalletAdapter(),
            new SolletExtensionWalletAdapter(),
            new SolletWalletAdapter(),
        ],
        [solNetwork]
    );

    const wallets = useMemo(
        () => [ 
        ],
        [solNetwork]
    );

    
  return (

    <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
              <WalletModalProvider>
                {/* homePg App1 */}
                <div id="MainDiv" className={classInfo} >
                    <Solist setClassInfo={setClassInfo}/>
                </div> 
              </WalletModalProvider>
          </WalletProvider>
      </ConnectionProvider>
      
  );
}

export default App;
